var DATE_FORMAT, DAYS_LABELS, MONTHS_LABELS, NEW_SESSION_DEFAULT_NAME, SESSION_LIST_CHANGE_EVENT, SessionListView, getCurrentDate, getState;

import Marionette from 'backbone.marionette';

import template from 'templates/gamemaster/sessionList';

import Toastr from 'toastr';

import Swal from 'lib/swal';

import _ from 'lodash';

import Path from 'path';

import DatePicker from 'js-datepicker';

import Moment from 'moment';

import Socket from 'lib/socket';

import SocketUtils from 'lib/socket/utils';

NEW_SESSION_DEFAULT_NAME = "New session";

SESSION_LIST_CHANGE_EVENT = 'session:list:change';

DATE_FORMAT = 'DD/MM/YYYY';

DAYS_LABELS = ['D', 'L', 'M', 'M', 'J', 'V', 'S'];

MONTHS_LABELS = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

getCurrentDate = function() {
  var currentDate;
  currentDate = new Date();
  return `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
};

getState = function(session) {
  if (session.has_started) {
    if (session.has_game_started) {
      if (session.has_ended) {
        return 'Ended';
      } else {
        return 'Running';
      }
    } else {
      return 'Started';
    }
  } else {
    return 'Pending';
  }
};

export default SessionListView = (function() {
  class SessionListView extends Marionette.View {
    initialize() {
      console.debug('Initializing SessionListView:', this.options);
      SocketUtils.registerViewSubscription(this, `${SESSION_LIST_CHANGE_EVENT}`, function() {
        return this.refreshList(this.referenceDate);
      });
      return this.refreshList();
    }

    templateContext() {
      return {
        createLink: `\#${Path.dirname(this.options.currentPath)}/sessioncreate`
      };
    }

    serializeData() {
      return {
        sessions: _.isEmpty(this.sessions) ? null : this.sessions
      };
    }

    async refreshList(referenceDate) {
      var currentDate, err, result;
      if (referenceDate == null) {
        referenceDate = new Date();
      }
      try {
        this.sessions = null;
        result = (await Socket.emit('session:list', referenceDate));
        console.debug('Received sessions:', result);
        this.referenceDate = referenceDate;
        currentDate = getCurrentDate();
        this.minDate = currentDate > result.min_date ? result.min_date : currentDate;
        this.maxDate = currentDate < result.max_date ? result.max_date : currentDate;
        this.sessions = this.parseSessions(result.sessions);
        return this.render();
      } catch (error) {
        err = error;
        console.error(err);
        return Toastr.error(`Error listing sessions: ${err}`);
      }
    }

    parseSessions(source) {
      if (source == null) {
        source = [];
      }
      source.forEach(function(session) {
        return session.state = getState(session);
      });
      // sort by creation date desc.
      _.sortBy(source, 'creation_date');
      _.remove(source, function(session) {
        return session.test_session;
      });
      source.reverse();
      return source;
    }

    onRender() {
      var currentDate;
      currentDate = new Date();
      if (this.sessions != null) {
        this.setDisplayedDate(this.referenceDate);
        this.datePicker = DatePicker(this.ui.dateSelector[0], {
          dateSelected: this.referenceDate,
          minDate: new Date(this.minDate),
          maxDate: new Date(this.maxDate),
          customDays: DAYS_LABELS,
          customMonths: MONTHS_LABELS
        });
        return this.datePicker.onSelect = (picker, date) => {
          return this.refreshList(date);
        };
      }
    }

    showDateSelector(event) {
      event.stopPropagation(); // picker won't show if not present.
      return this.datePicker.show();
    }

    setDisplayedDate(date) {
      if (date != null) {
        return this.ui.dateDisplay.text(Moment(date).format(DATE_FORMAT));
      }
    }

    handleSelectSession(event) {
      var currentSession, sessionId;
      currentSession = event.currentTarget;
      sessionId = currentSession.dataset.id;
      console.debug('Session selected:', sessionId);
      return this.trigger('navigate', Path.join(this.options.currentPath, sessionId));
    }

    handleDeleteSession(event) {
      var currentTarget;
      event.stopPropagation();
      event.preventDefault();
      currentTarget = event.currentTarget;
      return Swal.confirm(`Delete session \"${currentTarget.dataset.sessionname}\"?`, async function() {
        var err, sessionId;
        sessionId = currentTarget.dataset.sessionid;
        try {
          await Socket.emit('session:delete', sessionId);
          return Toastr.success('Session deleted.');
        } catch (error) {
          err = error;
          debug('Error deleting session:', err);
          return Toastr.error('Error deleting session.');
        }
      });
    }

  };

  SessionListView.prototype.template = template;

  SessionListView.prototype.className = 'sessionList container';

  SessionListView.prototype.ui = {
    dateSelector: '#dateSelector',
    dateDisplay: '#dateDisplay',
    createButton: '#createButton'
  };

  SessionListView.prototype.events = {
    'click #dateSelector > *': 'showDateSelector',
    'click .session': 'handleSelectSession',
    'click .deleteButton': 'handleDeleteSession'
  };

  return SessionListView;

}).call(this);
