import Moment from 'moment';

import _ from 'lodash';

export default function(date, format) {
  if (_.isDate(date) || _.isString(date)) {
    return Moment(date).format(format);
  } else {
    return `\#\#NOT A DATE:${date}\#\#`;
  }
};
