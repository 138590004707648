var LOCKED_CLASS, SESSION_CHANGE_EVENT, SessionDetailsView;

import Marionette from 'backbone.marionette';

import template from 'templates/gamemaster/sessionDetails';

import Backbone from 'backbone';

import Toastr from 'toastr';

import _ from 'lodash';

import TeamListView from 'views/gamemaster/sessionDetails/teamList';

import GameSettingsView from 'views/gamemaster/sessionDetails/gameSettings';

import Socket from 'lib/socket';

import SocketUtils from 'lib/socket/utils';

import Swal from 'lib/swal';

SESSION_CHANGE_EVENT = 'session:change';

LOCKED_CLASS = 'locked';

export default SessionDetailsView = (function() {
  class SessionDetailsView extends Marionette.View {
    async initialize() {
      console.debug('Initializing SessionDetailsView:', this.options);
      this.model = new Backbone.Model();
      this.sessionId = _.head(this.options.remainingPath);
      if (_.isEmpty(this.sessionId)) {
        return Toastr.error('Session id is missing.');
      } else {
        SocketUtils.registerViewSubscription(this, `${SESSION_CHANGE_EVENT}:${this.sessionId}`, this.updateSessionData);
        await this.refreshSessionData();
        await this.checkForRunningTests();
        this.showTeamList();
        this.showGameSettings();
        return this.updateStartEndState();
      }
    }

    templateContext() {
      return {
        hasTestsRunning: this.testsRunning
      };
    }

    onRender() {
      this.stickit();
      this.ui.startGameButton.enableLongpress(1.5);
      this.ui.startSessionButton.enableLongpress(.5);
      this.ui.endButton.enableLongpress();
      return this.ui.resetButton.enableLongpress(1.5);
    }

    async checkForRunningTests() {
      var sessionList;
      sessionList = (await Socket.emit('session:list:started'));
      this.testsRunning = (_.find(sessionList, function(session) {
        return session.test_session;
      })) != null;
      return this.render();
    }

    async refreshSessionData() {
      var err, sessionData;
      try {
        sessionData = (await Socket.emit('session:details', this.sessionId));
        return this.updateSessionData(sessionData);
      } catch (error) {
        err = error;
        console.error('Error fetching session:', err);
        return Toastr.error(`Error fetching session: ${err}`);
      }
    }

    updateSessionData(sessionData) {
      if (sessionData != null) {
        console.debug('Received session data:', sessionData);
        return this.model.set(sessionData);
      } else {
        return Toastr.error(`Invalid session Id: ${this.sessionId}`);
      }
    }

    showTeamList() {
      return this.showChildView('teamListRegion', new TeamListView({
        sessionId: this.sessionId,
        teams: this.model.get('teams')
      }));
    }

    showGameSettings() {
      return this.showChildView('gameSettingsRegion', new GameSettingsView({
        sessionId: this.sessionId,
        game_settings: this.model.get('game_settings')
      }));
    }

    handleEditSessionName() {
      var currentName;
      currentName = this.model.get('name');
      return Swal.promptText(null, 'Enter new name', currentName, async(newName) => {
        var err;
        if (!_.isEmpty(newName) && newName !== currentName) {
          try {
            return (await Socket.emit('session:rename', this.sessionId, newName));
          } catch (error) {
            err = error;
            console.error('Error renaming session:', err);
            return Toastr.error('Error renaming session.');
          }
        }
      });
    }

    async handleStartGame() {
      var err;
      try {
        return (await Socket.emit('session:start:game', this.sessionId));
      } catch (error) {
        err = error;
        console.error('Error starting session:', err);
        return Toastr.error(`Error starting session: ${err}`);
      }
    }

    async handleStartSession() {
      var err;
      try {
        return (await Socket.emit('session:start', this.sessionId));
      } catch (error) {
        err = error;
        console.error('Error initializing session:', err);
        return Toastr.error(`Error initializing session: ${err}`);
      }
    }

    async handleStop() {
      var err;
      try {
        return (await Socket.emit('session:end', this.sessionId));
      } catch (error) {
        err = error;
        console.error('Error ending session:', err);
        return Toastr.error(`Error ending session: ${err}`);
      }
    }

    async handleReset() {
      var err;
      try {
        return (await Socket.emit('session:reset', this.sessionId));
      } catch (error) {
        err = error;
        console.error('Error resetting session:', err);
        return Toastr.error(`Error resetting session: ${err}`);
      }
    }

    async updateStartEndState() {
      console.debug('Updating buttons state.', this.model);
      await this.ensureRendered();
      if (this.hasSessionEnded()) {
        this.ui.controlButtons.hide();
        this.ui.resetButton.show();
        return this.ui.gameSettings.addClass(LOCKED_CLASS);
      } else if (this.hasGameStarted()) {
        this.ui.controlButtons.hide();
        this.ui.endButton.show();
        return this.ui.gameSettings.addClass(LOCKED_CLASS);
      } else if (this.hasSessionStarted()) {
        this.ui.controlButtons.hide();
        this.ui.startGameButton.show();
        return this.ui.gameSettings.addClass(LOCKED_CLASS);
      } else {
        this.ui.controlButtons.hide();
        this.ui.startSessionButton.show();
        return this.ui.gameSettings.removeClass(LOCKED_CLASS);
      }
    }

    hasSessionStarted() {
      return this.model.get('has_started');
    }

    hasSessionEnded() {
      return this.model.get('has_ended');
    }

    hasGameStarted() {
      return this.model.get('has_game_started');
    }

  };

  SessionDetailsView.prototype.template = template;

  SessionDetailsView.prototype.className = 'sessionDetails container';

  SessionDetailsView.prototype.ui = {
    controlButtons: '.controlButton',
    startSessionButton: '.startSessionButton',
    startGameButton: '.startGameButton',
    endButton: '.endButton',
    resetButton: '.resetButton',
    gameSettings: '#gameSettings'
  };

  SessionDetailsView.prototype.modelEvents = {
    change: 'updateStartEndState'
  };

  SessionDetailsView.prototype.regions = {
    teamListRegion: '#teamList',
    gameSettingsRegion: '#gameSettings'
  };

  SessionDetailsView.prototype.events = {
    'click #nameLabel': 'handleEditSessionName',
    'longpress @ui.startSessionButton': 'handleStartSession',
    'longpress @ui.startGameButton': 'handleStartGame',
    'longpress @ui.endButton': 'handleStop',
    'longpress @ui.resetButton': 'handleReset'
  };

  SessionDetailsView.prototype.bindings = {
    '#idLabel': {
      observe: 'id',
      onGet: function(value) {
        if (value) {
          return `\#${value}`;
        } else {
          return '';
        }
      }
    },
    '#nameLabel': 'name'
  };

  return SessionDetailsView;

}).call(this);
