var ROOM_MAPPING_CHANGE_EVENT, ZONE_CHANGE_EVENT, ZONE_SESSION_EVENT, ZoneView, debug;

import Backbone from 'backbone';

import Marionette from 'backbone.marionette';

import template from 'templates/gamemaster/monitor/zone';

import RoomView from 'views/gamemaster/monitor/room';

import Socket from 'lib/socket';

import SocketUtils from 'lib/socket/utils';

import DateUtils from 'lib/date';

import Toastr from 'toastr';

import Debug from 'debug';

debug = Debug('happinov:gamemaster:monitor:zone');

ZONE_SESSION_EVENT = 'zone:session';

ZONE_CHANGE_EVENT = 'zone:change';

ROOM_MAPPING_CHANGE_EVENT = 'room:mapping:change';

export default ZoneView = (function() {
  class ZoneView extends Marionette.CollectionView {
    initialize() {
      debug('Initializing zone view:', this.options);
      this.collection = new Backbone.Collection();
      SocketUtils.registerViewSubscription(this, ROOM_MAPPING_CHANGE_EVENT, this.fetchZoneRooms);
      SocketUtils.registerViewSubscription(this, ZONE_CHANGE_EVENT, this.updateZoneSession);
      this.fetchZoneRooms();
      return this.refreshZoneSession();
    }

    async fetchZoneRooms() {
      var rooms;
      rooms = (await Socket.emit('get:game:rooms'));
      return this.collection.reset(this.model.get('rooms').map(function(id) {
        return {
          id: id,
          name: rooms[id].name
        };
      }));
    }

    async refreshZoneSession() {
      var err, zoneSessions;
      try {
        zoneSessions = (await Socket.emit(ZONE_SESSION_EVENT));
        debug('Received zone sessions:', zoneSessions);
        return this.updateZoneSession(zoneSessions);
      } catch (error) {
        err = error;
        debug('Error fetching zone sessions');
        return Toastr.error(`Error fetching zone sessions: ${err}`);
      }
    }

    updateZoneSession(zoneSessions) {
      var currentZone, gameSettings, session;
      debug('Updating zone session', zoneSessions);
      if (zoneSessions[this.model.get('id')] != null) {
        session = zoneSessions[this.model.get('id')];
        session.sessionStartTime = DateUtils.formatTime(session.game_start_date);
        session.current_state.stepEndTime = DateUtils.formatTime(session.current_state.stepEndDate);
        gameSettings = session.game_settings;
        currentZone = gameSettings != null ? gameSettings[Math.floor((session.current_state.step - 1) / 4)] : void 0;
        session.current_state.zoneEndTime = DateUtils.formatTime(session.current_state.zoneEndTime);
        this.model.set('session', session);
      } else {
        this.model.unset('session');
      }
      return this.render();
    }

  };

  ZoneView.prototype.template = template;

  ZoneView.prototype.className = 'zone card';

  ZoneView.prototype.childView = RoomView;

  ZoneView.prototype.childViewContainer = '.rooms';

  return ZoneView;

}).call(this);
