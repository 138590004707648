{
  "name": "divertyserver",
  "displayName": "Happi Diverty Server",
  "version": "1.0.1",
  "description": "",
  "author": "Happinov",
  "license": "UNLICENSED",
  "scripts": {
    "preinstall": "check-node-version --node \"$(< .nvmrc)\""
  },
  "devDependencies": {
    "backbone": "^1.4.0",
    "backbone.marionette": "^4.1.3",
    "backbone.stickit": "^0.9.2",
    "bootstrap": "^4.6.1",
    "chai": "^4.4.1",
    "connect-livereload": "^0.6.1",
    "esbuild": "^0.20.2",
    "esbuild-coffeescript": "^2.2.0",
    "esbuild-plugin-copy": "^2.1.1",
    "esbuild-plugin-handlebars": "^1.0.3",
    "esbuild-sass-plugin": "^3.2.0",
    "fontawesome-pro": "bitbucket:coqsenpate/fontawesome-pro-6.5.1",
    "grunt": "^1.3.0",
    "grunt-contrib-clean": "^2.0.0",
    "grunt-contrib-copy": "^1.0.0",
    "grunt-contrib-symlink": "^1.0.0",
    "grunt-contrib-watch": "^1.1.0",
    "grunt-esbuild": "^1.0.1",
    "grunt-express-server": "^0.5.4",
    "grunt-mocha-test": "^0.13.3",
    "grunt-newer": "^1.3.0",
    "grunt-open": "^0.2.4",
    "grunt-replace": "^2.0.2",
    "handlebars": "^4.7.7",
    "jquery": "^3.6.0",
    "js-datepicker": "^5.18.0",
    "load-grunt-tasks": "^5.1.0",
    "moment-duration-format": "^2.3.2",
    "path-browserify": "^1.0.1",
    "popper.js": "^1.16.1",
    "semver": "^6.3.0",
    "socket.io-client": "^4.0.0",
    "sprintf-js": "^1.0.3",
    "sweetalert2": "^11.10.7",
    "time-grunt": "^2.0.0",
    "toastr": "^2.1.4",
    "ua-parser-js": "^0.7.24",
    "util": "^0.12.3"
  },
  "dependencies": {
    "ajv": "^8.1.0",
    "bcrypt": "^5.1.1",
    "busboy": "^1.6.0",
    "coffeescript": "^2.5.1",
    "compression": "^1.7.4",
    "cors": "^2.8.5",
    "cron": "^1.8.2",
    "debug": "^4.3.1",
    "errorhandler": "^1.5.1",
    "express": "^4.19.2",
    "express-session": "^1.17.1",
    "extract-zip": "^2.0.1",
    "lodash": "^4.17.21",
    "log4js": "^6.9.1",
    "md5": "^2.3.0",
    "mdns": "^2.7.2",
    "moment": "^2.29.1",
    "mv": "^2.1.1",
    "mysql": "^2.18.1",
    "project-name-generator": "^2.1.9",
    "socket.io": "^4.0.0",
    "uuid": "^8.3.2",
    "ws": "^8.6.0",
    "zip-stream": "^6.0.1"
  }
}
