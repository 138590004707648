var AVAILABLE_TEAM_SIZES, SESSION_CHANGE_EVENT, TeamListView, debug,
  boundMethodCheck = function(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new Error('Bound instance method accessed before binding'); } };

import Backbone from 'backbone';

import Marionette from 'backbone.marionette';

import template from 'templates/gamemaster/sessionDetails/teamList';

import DeviceSelectorModal from 'views/common/deviceSelectorModal';

import _ from 'lodash';

import Toastr from 'toastr';

import Swal from 'lib/swal';

import Socket from 'lib/socket';

import SocketUtils from 'lib/socket/utils';

import Modal from 'lib/modal';

import Language from 'lib/language';

import Config from 'etc/config';

import Debug from 'debug';

debug = Debug('happinov:views:manage:sessionEditor:teamList');

AVAILABLE_TEAM_SIZES = [2, 3, 4, 5, 6];

SESSION_CHANGE_EVENT = 'session:change';

export default TeamListView = (function() {
  class TeamListView extends Marionette.View {
    constructor() {
      super(...arguments);
      this.updateSessionDetails = this.updateSessionDetails.bind(this);
    }

    initialize() {
      debug('Initializing TeamList View:', this.options);
      ({sessionId: this.sessionId} = this.options);
      this.model = new Backbone.Model();
      SocketUtils.registerViewSubscription(this, `${SESSION_CHANGE_EVENT}:${this.sessionId}`, this.updateSessionDetails);
      return this.updateSessionDetails(this.options);
    }

    async updateSessionDetails(details) {
      var teams;
      boundMethodCheck(this, TeamListView);
      debug('Updating session details');
      teams = _.cloneDeep(details.teams);
      teams.forEach(function(team) {
        return team.languageFlag = Language.getFlag(team.language);
      });
      this.model.set({
        teams: _.sortBy(teams, 'id')
      });
      await this.ensureRendered();
      return this.render();
    }

    templateContext() {
      return {
        availableTeamSizes: AVAILABLE_TEAM_SIZES,
        availableLanguages: Config.languages.available,
        sessionDetailsDownloadUrl: `services/download_session_details/${this.sessionId}`
      };
    }

    onRender() {
      // Select players and language value in each team row
      return this.model.get('teams').forEach((team) => {
        this.ui.playersSelector.filter(`[data-teamid=\"${team.id}\"]`).find(`option[value=\"${team.players}\"]`).attr('selected', true);
        return this.ui.languageSelector.filter(`[data-teamid=\"${team.id}\"]`).find(`option[value=\"${team.language}\"]`).attr('selected', true);
      });
    }

    handleEditTeamName(event) {
      var currentName, currentTarget, teamId;
      currentTarget = event.currentTarget;
      currentName = currentTarget.dataset.teamname;
      teamId = currentTarget.dataset.teamid;
      return Swal.promptText(null, 'Enter new name', currentName, async(newName) => {
        var err;
        if (!_.isEmpty(newName) && newName !== currentName) {
          try {
            return (await Socket.emit('team:rename', this.sessionId, teamId, newName));
          } catch (error) {
            err = error;
            console.error('Error renaming team:', err);
            return Toastr.error('Error renaming team.');
          }
        }
      });
    }

    async handleEditTeamPlayerValue(event) {
      var currentTarget, err, newPlayerValue, teamId;
      currentTarget = event.currentTarget;
      newPlayerValue = parseInt(currentTarget.value, 10);
      teamId = parseInt(currentTarget.dataset.teamid, 10);
      if (_.isFinite(newPlayerValue)) {
        try {
          return (await Socket.emit('team:change:player:count', this.sessionId, teamId, newPlayerValue));
        } catch (error) {
          err = error;
          console.error('Error changing team player value:', err);
          return Toastr.error('Error changing team player value.');
        }
      }
    }

    async handleEditTeamLanguage(event) {
      var currentTarget, err, newLang, teamId;
      currentTarget = event.currentTarget;
      newLang = currentTarget.value;
      teamId = parseInt(currentTarget.dataset.teamid, 10);
      if (!_.isEmpty(newLang)) {
        try {
          return (await Socket.emit('team:change:language', this.sessionId, teamId, newLang));
        } catch (error) {
          err = error;
          console.error('Error changing team language:', err);
          return Toastr.error('Error changing team language.');
        }
      }
    }

    handleEditTeamDevices(event) {
      var currentSelection, currentTarget, i, len, ref, team, teamId, unavailableDevices, view;
      currentTarget = event.currentTarget;
      teamId = parseInt(currentTarget.dataset.teamid, 10);
      unavailableDevices = [];
      ref = this.model.get('teams');
      for (i = 0, len = ref.length; i < len; i++) {
        team = ref[i];
        if (team.id === teamId) {
          currentSelection = _.map(team.devices, 'uuid');
        } else {
          unavailableDevices.push(...(_.map(team.devices, 'uuid')));
        }
      }
      view = new DeviceSelectorModal({
        currentSelection: currentSelection,
        unavailableDevices: unavailableDevices,
        teamId: teamId
      });
      view.on('submit', async(selection) => {
        var devicesToAdd, devicesToRemove, err;
        if (selection !== currentSelection) {
          try {
            devicesToAdd = _.difference(selection, currentSelection);
            devicesToRemove = _.difference(currentSelection, selection);
            if (devicesToAdd.length > 0) {
              await Socket.emit('session:device:add', this.sessionId, teamId, devicesToAdd);
            }
            if (devicesToRemove.length > 0) {
              return (await Socket.emit('session:device:remove', this.sessionId, teamId, devicesToRemove));
            }
          } catch (error) {
            err = error;
            console.error('Error adding devices:', err);
            return Toastr.error('Error adding devices.');
          }
        }
      });
      return Modal.show(view);
    }

    async handleAutoSelectDevices() {
      var err;
      try {
        return (await Socket.emit('session:auto:select:devices', this.sessionId));
      } catch (error) {
        err = error;
        console.error('Error selecting devices automatically:', err);
        return Toastr.error(err, 'Error selecting devices automatically');
      }
    }

  };

  TeamListView.prototype.template = template;

  TeamListView.prototype.className = 'teamList';

  TeamListView.prototype.ui = {
    playersSelector: '.playersSelector',
    languageSelector: '.languageSelector',
    autoSelectDevices: '.autoSelectDevices'
  };

  TeamListView.prototype.events = {
    'click .teamName': 'handleEditTeamName',
    'change @ui.playersSelector': 'handleEditTeamPlayerValue',
    'change @ui.languageSelector': 'handleEditTeamLanguage',
    'click .device': 'handleEditTeamDevices',
    'click @ui.autoSelectDevices': 'handleAutoSelectDevices'
  };

  return TeamListView;

}).call(this);
