var SettingsEditorModalView;

import Marionette from 'backbone.marionette';

import template from 'templates/gamemaster/sessionDetails/settingsEditorModal';

import SettingsEditorView from 'views/gamemaster/sessionDetails/settingsEditor';

export default SettingsEditorModalView = (function() {
  class SettingsEditorModalView extends Marionette.View {
    initialize() {
      console.debug('Initializing SettingsEditorModal View:', this.options);
      return ({game_settings: this.game_settings} = this.options);
    }

    onRender() {
      this.settingsEditorView = new SettingsEditorView({
        game_settings: this.game_settings
      });
      return this.showChildView('settingsRegion', this.settingsEditorView);
    }

    handleSubmit() {
      var data;
      data = this.settingsEditorView.getSettings();
      this.trigger('submit', data);
      return this.trigger('modal:close'); // don't close before getting current selection.
    }

  };

  SettingsEditorModalView.prototype.template = template;

  SettingsEditorModalView.prototype.className = 'settingsEditor modal-dialog modal-dialog-centered modal-lg';

  SettingsEditorModalView.prototype.regions = {
    settingsRegion: '#settingsRegion'
  };

  SettingsEditorModalView.prototype.ui = {
    submitButton: '.submitButton'
  };

  SettingsEditorModalView.prototype.events = {
    'click .submitButton': 'handleSubmit'
  };

  return SettingsEditorModalView;

}).call(this);
