var ClockView, DISPLAY_FORMAT, debug;

import Marionette from 'backbone.marionette';

import Time from 'lib/time';

import Moment from 'moment';

import MomentDurationFormatSetup from 'moment-duration-format';

import Debug from 'debug';

debug = Debug('happinov:common:clock');

DISPLAY_FORMAT = "HH:mm:ss";

export default ClockView = (function() {
  class ClockView extends Marionette.View {
    initialize() {
      var ref;
      debug('Initializing clock/countdown view:', this.options);
      if ((ref = this.options) != null ? ref.endDate : void 0) {
        debug('Initializing countdown with endDate:', this.options.endDate);
        this.endDate = Moment(this.options.endDate);
      }
      this.updateTime();
      return this.startClock();
    }

    onDestroy() {
      return this.stopClock();
    }

    startClock() {
      if (!this.clockId) {
        return this.clockID = Time.setInterval(500, () => {
          return this.updateTime();
        });
      } else {
        return debug('Clock already started');
      }
    }

    stopClock() {
      clearInterval(this.clockID);
      return this.clockID = null;
    }

    updateTime() {
      var currentTime, remainingMillis, remainingTime;
      if (this.endDate) {
        // Countdown
        if (this.endDate.diff() < 0) {
          remainingTime = "00:00:00";
          this.stopClock();
        } else {
          remainingMillis = this.endDate.diff();
          remainingTime = Moment.duration(remainingMillis, "milliseconds").format(DISPLAY_FORMAT, {
            trim: false
          });
        }
        return this.$el.html(remainingTime);
      } else {
        // Standard clock
        currentTime = Moment().format(DISPLAY_FORMAT);
        return this.$el.html(currentTime);
      }
    }

  };

  ClockView.prototype.template = false;

  ClockView.prototype.className = 'clock';

  return ClockView;

}).call(this);
