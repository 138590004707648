var AdminUserListView;

import Marionette from 'backbone.marionette';

import template from 'templates/admin/userList';

import Toastr from 'toastr';

import Socket from 'lib/socket';

export default AdminUserListView = (function() {
  class AdminUserListView extends Marionette.View {
    initialize() {
      console.debug('Initializing AdminUserListView:', this.options);
      this.userList = [];
      return this.updateList();
    }

    templateContext() {
      return {
        createLink: `\#${this.options.currentPath}/new`
      };
    }

    async updateList() {
      var err;
      try {
        this.userList = (await Socket.emit('user:list'));
        console.debug('Received user list:', this.userList);
        this.userList.forEach((user) => {
          return user.editLink = `\#${this.options.currentPath}/${user.id}`;
        });
        await this.ensureRendered();
        return this.render();
      } catch (error) {
        err = error;
        console.error('Error fetching user list:', err);
        return Toastr.error('Error fetching user list.');
      }
    }

    serializeData() {
      return {
        users: this.userList
      };
    }

  };

  AdminUserListView.prototype.template = template;

  AdminUserListView.prototype.className = 'userList container';

  return AdminUserListView;

}).call(this);
