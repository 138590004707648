var jqueryUtils;

import $ from 'jquery';

import Time from 'lib/time';

import Focus from 'lib/focus';

export default jqueryUtils = {
  makeLongpressAvailable: function() {
    return $.fn.enableLongpress = function(duration = 3) {
      return this.each(function() {
        var $el, activationStartMillis, activationTimer, clearTimer, progressbar, startTimer;
        $el = $(this);
        $el.addClass('longpressSource');
        $el.data('toggle', 'tooltip');
        progressbar = null;
        activationTimer = null;
        activationStartMillis = null;
        $el.tooltip({
          trigger: 'manual',
          // placement: 'bottom'
          html: true,
          title: function() {
            progressbar = document.createElement('progress');
            progressbar.className = 'longpress';
            return progressbar;
          }
        });
        startTimer = function() {
          $el.tooltip('show');
          progressbar.value = 0;
          progressbar.max = duration * 1000;
          activationStartMillis = Date.now();
          return activationTimer = Time.setInterval(200, async function() {
            progressbar.value = Date.now() - activationStartMillis;
            if (progressbar.value >= progressbar.max) {
              clearTimer();
              $el.addClass('flash');
              await Time.wait(100);
              $el.removeClass('flash');
              return $el.trigger('longpress');
            }
          });
        };
        clearTimer = function() {
          $el.tooltip('hide');
          Focus.clear();
          activationStartMillis = null;
          clearInterval(activationTimer);
          return activationTimer = null;
        };
        $el.on('click', function(event) {
          // disable normal click.
          event.stopPropagation();
          return event.preventDefault();
        });
        $el.on('doubleclick', function(event) {
          // disable normal click.
          event.stopPropagation();
          return event.preventDefault();
        });
        $el.on('pointerdown', function() {
          return startTimer();
        });
        $el.on('pointerup', function() {
          return clearTimer();
        });
        return $el.on('pointerleave', function() {
          return clearTimer();
        });
      });
    };
  }
};
