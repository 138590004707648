var AdminUserEditorView, NEW_ID;

import Marionette from 'backbone.marionette';

import template from 'templates/admin/userEditor';

import Backbone from 'backbone';

import Toastr from 'toastr';

import _ from 'lodash';

import Socket from 'lib/socket';

NEW_ID = 'new';

export default AdminUserEditorView = (function() {
  class AdminUserEditorView extends Marionette.View {
    initialize() {
      var ref;
      console.debug('Initializing AdminUserEditorView:', this.options);
      this.model = new Backbone.Model();
      this.userId = (ref = _.head(this.options.remainingPath)) != null ? ref : NEW_ID;
      if (this.userId === NEW_ID) {
        console.debug('Creating new user.');
        return this.isNewUser = true;
      } else {
        return this.loadUserData(this.userId);
      }
    }

    async loadUserData(userId) {
      var err, userDetails;
      try {
        userDetails = (await Socket.emit('user:details', userId));
        console.debug('Received user details:', userDetails);
        return this.model.set(userDetails);
      } catch (error1) {
        err = error1;
        if (err === 'NOT_FOUND') {
          err = 'User not found.';
        }
        return Toastr.error(err);
      }
    }

    onRender() {
      return this.stickit();
    }

    getPassword() {
      return this.model.get('password');
    }

    getPassword2() {
      return this.model.get('password2');
    }

    hasPassword() {
      return !_.isEmpty(this.getPassword());
    }

    checkPasswordsMatch() {
      return this.hasPassword() && this.getPassword() === this.getPassword2();
    }

    enableButtons() {
      return this.ui.buttons.removeAttr('disabled');
    }

    disableButtons() {
      return this.ui.buttons.attr('disabled', true);
    }

    async handleSubmit(event) {
      var err, error, userData;
      event.preventDefault();
      event.stopPropagation();
      console.debug('Form submitted.');
      this.disableButtons();
      if (this.isNewUser && !this.hasPassword()) {
        error = 'New user must have a password.';
      } else if (this.hasPassword() && !this.checkPasswordsMatch()) {
        error = 'Passwords don\'t match.';
      }
      if (error) {
        Toastr.error(error);
        return this.enableButtons();
      } else {
        try {
          userData = this.model.toJSON();
          if (_.isEmpty(userData.password)) { // could be an empty string if ever edited.
            delete userData.password;
          }
          delete userData.password2; // this one is never sent.
          console.debug('User data:', userData);
          if (this.isNewUser) {
            await Socket.emit('user:create', userData);
            Toastr.success('User created.');
          } else {
            await Socket.emit('user:update', userData);
            Toastr.success('User updated.');
          }
          return this.trigger('navigate', this.options.currentPath); // go back to list after success.
        } catch (error1) {
          err = error1;
          Toastr.error(err);
          return this.enableButtons();
        }
      }
    }

    handleReset() {
      this.model.clear();
      if (!this.isNewUser) {
        return this.loadUserData(this.userId);
      }
    }

  };

  AdminUserEditorView.prototype.template = template;

  AdminUserEditorView.prototype.className = 'userEditor container';

  AdminUserEditorView.prototype.ui = {
    buttons: 'button'
  };

  AdminUserEditorView.prototype.events = {
    'click .resetButton': 'handleReset',
    'submit form': 'handleSubmit'
  };

  AdminUserEditorView.prototype.bindings = {
    '#idLabel': {
      observe: 'id',
      onGet: function(value) {
        if (value) {
          return `\#${value}`;
        } else {
          return '#new';
        }
      }
    },
    '#usernameInput': 'username',
    '#nameInput': 'name',
    '#passwordInput': 'password',
    '#password2Input': 'password2',
    '#adminCheckbox': 'is_admin'
  };

  return AdminUserEditorView;

}).call(this);
