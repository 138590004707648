var SESSION_CHANGE_EVENT, TestModeMonitorView, debug,
  boundMethodCheck = function(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new Error('Bound instance method accessed before binding'); } };

import Marionette from 'backbone.marionette';

import template from 'templates/gamemaster/testMode/monitor';

import Toastr from 'toastr';

import Swal from 'lib/swal';

import Socket from 'lib/socket';

import SocketUtils from 'lib/socket/utils';

import ClockView from 'views/common/clock';

import Debug from 'debug';

debug = Debug('happinov:views:gamemaster:testmode:monitor');

SESSION_CHANGE_EVENT = 'session:change';

export default TestModeMonitorView = (function() {
  class TestModeMonitorView extends Marionette.View {
    constructor() {
      super(...arguments);
      this.endTestSession = this.endTestSession.bind(this);
    }

    async initialize() {
      console.debug('Initializing TestModeMonitorView:', this.options);
      this.sessionId = this.model.get('id');
      if (this.sessionId == null) {
        return Toastr.error('Session id is missing.');
      } else {
        SocketUtils.registerViewSubscription(this, `${SESSION_CHANGE_EVENT}:${this.sessionId}`, this.updateSessionData);
        return (await this.refreshSessionData());
      }
    }

    onRender() {
      return this.showChildView('countdownRegion', new ClockView({
        endDate: this.model.get('current_state').stepEndDate
      }));
    }

    async refreshSessionData() {
      var err, sessionData;
      try {
        sessionData = (await Socket.emit('test:session:details', this.sessionId));
        return this.updateSessionData(sessionData);
      } catch (error) {
        err = error;
        console.error('Error fetching session:', err);
        return Toastr.error(`Error fetching session: ${err}`);
      }
    }

    updateSessionData(sessionData) {
      if (sessionData != null) {
        console.debug('Received session data:', sessionData);
        this.model.set(sessionData);
        return this.render();
      } else {
        return Toastr.error(`Invalid session Id: ${this.sessionId}`);
      }
    }

    displayCountdown() {
      var endDate;
      return endDate = this.model.get('current_state').stepEndDate;
    }

    endTestSession() {
      boundMethodCheck(this, TestModeMonitorView);
      return Swal.confirm(`Delete session \"${this.model.get('name')}\"?`, async() => {
        var err;
        try {
          await Socket.emit('session:end', this.sessionId);
          return Toastr.success('Session ended.');
        } catch (error) {
          err = error;
          debug('Error ending session:', err);
          return Toastr.error('Error deleting session.');
        }
      });
    }

  };

  TestModeMonitorView.prototype.template = template;

  TestModeMonitorView.prototype.className = 'testModeMonitor';

  TestModeMonitorView.prototype.regions = {
    countdownRegion: '.countdownRegion'
  };

  TestModeMonitorView.prototype.ui = {
    'deleteButton': '.deleteButton'
  };

  TestModeMonitorView.prototype.events = {
    'click @ui.deleteButton': 'endTestSession'
  };

  return TestModeMonitorView;

}).call(this);
