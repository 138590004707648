var TeamView, debug;

import Marionette from 'backbone.marionette';

import template from 'templates/gamemaster/monitor/team';

import Socket from 'lib/socket';

import SocketUtils from 'lib/socket/utils';

import Language from 'lib/language';

import Debug from 'debug';

debug = Debug('happinov:gamemaster:monitor:team');

export default TeamView = (function() {
  class TeamView extends Marionette.View {
    initialize() {
      debug('Initializing teamView:', this.options);
      return this.model.set('languageFlag', Language.getFlag(this.model.get('language')));
    }

    onRender() {
      return this.ui.teamDetails.popover();
    }

    templateContext() {
      return {
        globalScore: `SCORE:  ${this.model.get('score').global.points} pts / ${this.model.get('score').global.rating}`,
        scoreDetail: () => {
          var ref, result, roomId, score;
          result = "";
          ref = this.model.get('score');
          for (roomId in ref) {
            score = ref[roomId];
            if (roomId !== 'global') {
              result += `${roomId}:  ${score.points} pts / ${score.rating}\n`;
            }
          }
          return result;
        }
      };
    }

  };

  TeamView.prototype.template = template;

  TeamView.prototype.className = 'team';

  TeamView.prototype.ui = {
    teamDetails: '.teamDetails'
  };

  return TeamView;

}).call(this);
