import * as Handlebars from 'handlebars/runtime';
import deviceTypeIcon from 'templates/partials/deviceTypeIcon';
import navbar from 'templates/partials/navbar';
Handlebars.registerHelper({});
Handlebars.registerPartial({deviceTypeIcon,navbar});
export default Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"compilationDate\">Compilation date: "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"compilationDate") : depth0), depth0))
    + "</div>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1>Manage Game</h1>\n<div class=\"gameDefinition card mb-3 p-3\">\n	<div class=\"gameId\"><b>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"gameId") : depth0), depth0))
    + "</b></div>\n	<div class=\"gameVersion\">v"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"gameVersion") : depth0), depth0))
    + "</div>\n	"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"compilationDate") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":1},"end":{"line":5,"column":103}}})) != null ? stack1 : "")
    + "\n</div>\n<button class=\"btn btn-primary\" id=\"uploadGameDataButton\">Upload game data</button>\n<a class=\"btn btn-secondary\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"gamedataDownloadUrl") : depth0), depth0))
    + "\" target=\"_blank\">Download game data</a>\n\n<div id=\"dragOverlay\">\n	<div class=\"dropContainer\">\n		<div class=\"dropMessage\">\n			<i class=\"icon fas fa-file-import\"></i>\n			<div class=\"text\">Drop file to import</div>\n		</div>\n	</div>\n</div>\n";
},"useData":true});