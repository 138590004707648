import * as Handlebars from 'handlebars/runtime';
import deviceTypeIcon from 'templates/partials/deviceTypeIcon';
import navbar from 'templates/partials/navbar';
Handlebars.registerHelper({});
Handlebars.registerPartial({deviceTypeIcon,navbar});
export default Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "			<h4 class=\"faded\">Session controls not available when tests are running.</h4>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "			<button class=\"btn btn-primary controlButton startSessionButton\"><i class=\"far fa-rocket-launch\"></i> Start Session</button>\n			<button class=\"btn btn-success controlButton startGameButton\"><i class=\"far fa-dice\"></i> Start Game</button>\n			<button class=\"btn btn-danger controlButton endButton\"><i class=\"far fa-stop-circle\"></i> Stop</button>\n			<button class=\"btn btn-warning controlButton resetButton\"><i class=\"far fa-redo\"></i> Reset</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"sessionInfo card\">\n	<h1 class=\"card-header\">\n		<span id=\"nameLabel\" class=\"editable\"></span>\n		<span id=\"idLabel\"></span>\n	</h1>\n	<div class=\"card-body\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasTestsRunning") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":7,"column":2},"end":{"line":14,"column":9}}})) != null ? stack1 : "")
    + "	</div>\n</div>\n<div id=\"gameSettings\" class=\"card\"></div>\n<div id=\"teamList\" class=\"card\"></div>\n";
},"useData":true});