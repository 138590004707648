var CHECKED_ATTR, CONNECTED_CLASS, DISABLED_ATTR, DISABLED_CLASS, DevicePickerModalView, DeviceView;

import Marionette from 'backbone.marionette';

import template from 'templates/common/devicePickerModal';

import itemTemplate from 'templates/common/devicePickerModal/item';

import Backbone from 'backbone';

import Toastr from 'toastr';

import _ from 'lodash';

import Socket from 'lib/socket';

import SocketUtils from 'lib/socket/utils';

import DeviceName from 'lib/deviceName';

CONNECTED_CLASS = 'connected';

DISABLED_ATTR = 'disabled';

DISABLED_CLASS = 'disabled';

CHECKED_ATTR = 'checked';

DeviceView = (function() {
  class DeviceView extends Marionette.View {
    initialize() {
      return console.debug('Initializing DeviceView:', this.options);
    }

    onRender() {
      if (this.model.get('is_active')) {
        return this.$el.addClass(DISABLED_CLASS);
      }
    }

    isSelected() {
      return this.ui.checkbox.prop(CHECKED_ATTR);
    }

    async setSelected(bool) {
      await this.ensureRendered();
      return this.ui.checkbox.prop(CHECKED_ATTR, bool === true);
    }

    async setConnected(flag) {
      await this.ensureRendered();
      if (flag) {
        return this.$el.addClass(CONNECTED_CLASS);
      } else {
        return this.$el.removeClass(CONNECTED_CLASS);
      }
    }

    getUuid() {
      return this.model.get('uuid');
    }

    getType() {
      return this.model.get('type');
    }

    getManufacturer() {
      return this.model.get('manufacturer');
    }

    getModel() {
      return this.model.get('model');
    }

    templateContext() {
      return {
        modelName: DeviceName.lookup(this.getManufacturer(), this.getModel())
      };
    }

  };

  DeviceView.prototype.template = itemTemplate;

  DeviceView.prototype.className = 'device';

  DeviceView.prototype.triggers = {
    'change @ui.checkbox': 'change'
  };

  DeviceView.prototype.ui = {
    checkbox: 'input'
  };

  return DeviceView;

}).call(this);

export default DevicePickerModalView = (function() {
  class DevicePickerModalView extends Marionette.CollectionView {
    async setSubmitDisabled(flag) {
      await this.ensureRendered();
      if (flag) {
        return this.ui.submitButton.attr(DISABLED_ATTR, 'true');
      } else {
        return this.ui.submitButton.removeAttr(DISABLED_ATTR);
      }
    }

    initialize() {
      console.debug('Initializing DevicePickerModalView:', this.options);
      ({currentSelection: this.currentSelection} = this.options);
      this.collection = new Backbone.Collection();
      this.collection.comparator = 'sortName';
      return SocketUtils.registerDeviceSubscriptions(this, this.updateDeviceList, this.updateDisplayedStates, function(err) {
        console.error(err);
        return Toastr.error(`Echec du listage des appareils : ${err}`);
      });
    }

    onRender() {
      return this.setSubmitDisabled(_.isEmpty(this.currentSelection));
    }

    parseList(list) {
      list.forEach(function(item) {
        item.id = item.uuid;
        return item.sortName = item.name.toLowerCase();
      });
      return list;
    }

    updateDeviceList(list) {
      console.debug('Received list:', list);
      this.collection.reset(this.parseList(list));
      if (this.currentSelection) {
        return this.children.each((item) => {
          return item.setSelected(this.currentSelection.includes(item.getUuid()));
        });
      }
    }

    updateDisplayedStates(states) {
      return this.children.each(function(item) {
        return item.setConnected(_.includes(states, item.getUuid()));
      });
    }

    getSelection() {
      var selection;
      selection = [];
      this.children.each(function(item) {
        if (item.isSelected()) {
          return selection.push(item.getUuid());
        }
      });
      return selection;
    }

    handleSubmit() {
      this.trigger('submit', this.getSelection());
      return this.trigger('modal:close'); // don't close before getting current selection.
    }

    handleSelectionChange() {
      return this.setSubmitDisabled(_.isEmpty(this.getSelection()));
    }

  };

  DevicePickerModalView.prototype.template = template;

  DevicePickerModalView.prototype.className = 'devicePicker modal-dialog modal-dialog-centered modal-dialog-scrollable';

  DevicePickerModalView.prototype.childView = DeviceView;

  DevicePickerModalView.prototype.childViewContainer = '.modal-body';

  DevicePickerModalView.prototype.childViewEvents = {
    change: 'handleSelectionChange'
  };

  DevicePickerModalView.prototype.ui = {
    submitButton: '.submitButton'
  };

  DevicePickerModalView.prototype.events = {
    'click .submitButton': 'handleSubmit'
  };

  return DevicePickerModalView;

}).call(this);
