var DateUtils;

export default DateUtils = {
  formatTime: function(date, utc = false) {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    if (utc) {
      return `${DateUtils.formatValue(date.getUTCHours())}:${DateUtils.formatValue(date.getMinutes())}:${DateUtils.formatValue(date.getSeconds())}`;
    } else {
      return `${DateUtils.formatValue(date.getHours())}:${DateUtils.formatValue(date.getMinutes())}:${DateUtils.formatValue(date.getSeconds())}`;
    }
  },
  formatValue: function(val) {
    if (val < 10) {
      return `0${val}`;
    } else {
      return val;
    }
  }
};
