var CONNECTED_CLASS, DEFAULT_DEVICE_NAME, DEVICE_MESSAGE_RECEIVED_SUBSCRIPTION, DeviceMessagesView, TOKEN_SEPARATOR, formatMessage,
  boundMethodCheck = function(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new Error('Bound instance method accessed before binding'); } };

import Marionette from 'backbone.marionette';

import template from 'templates/gamemaster/deviceMessages';

import Toastr from 'toastr';

import _ from 'lodash';

import Moment from 'moment';

import Socket from 'lib/socket';

import SocketUtils from 'lib/socket/utils';

import MessagingConsoleView from 'views/common/messagingConsole';

DEVICE_MESSAGE_RECEIVED_SUBSCRIPTION = 'device:message:received';

CONNECTED_CLASS = 'connected';

DEFAULT_DEVICE_NAME = 'Inconnu';

TOKEN_SEPARATOR = ',';

formatMessage = function(message, args) {
  var formatedArgs, result;
  result = message;
  if (!_.isEmpty(args)) {
    formatedArgs = _.map(args, function(value) {
      return JSON.stringify(value);
    });
    result += ` (${formatedArgs.join(', ')})`;
  }
  return result;
};

export default DeviceMessagesView = (function() {
  class DeviceMessagesView extends Marionette.View {
    constructor() {
      super(...arguments);
      this.handleMessageSend = this.handleMessageSend.bind(this);
      this.updateDeviceList = this.updateDeviceList.bind(this);
      this.updateStateDisplay = this.updateStateDisplay.bind(this);
      this.handleMessageReceived = this.handleMessageReceived.bind(this);
    }

    initialize() {
      console.debug('Initializing DeviceMessagesView:', this.options);
      this.deviceUuid = _.head(this.options.remainingPath);
      SocketUtils.registerDeviceSubscriptions(this, this.updateDeviceList, this.updateStateDisplay, function(err) {
        console.error(err);
        return Toastr.error(`Error listing devices: ${err}`);
      });
      return SocketUtils.registerViewSubscription(this, `${DEVICE_MESSAGE_RECEIVED_SUBSCRIPTION}:${this.deviceUuid}`, this.handleMessageReceived);
    }

    onRender() {
      this.messagingConsole = new MessagingConsoleView();
      this.messagingConsole.on('submit', this.handleMessageSend);
      return this.showChildView('messageRegion', this.messagingConsole);
    }

    handleMessageSend(str) {
      var args, message;
      boundMethodCheck(this, DeviceMessagesView);
      [message, ...args] = str.split(TOKEN_SEPARATOR);
      Socket.emit('device:message:send', this.deviceUuid, message, ...args);
      return this.messagingConsole.addOutboundMessage(formatMessage(message, args), str);
    }

    async updateDeviceList(deviceList) {
      var device, i, len, ref;
      boundMethodCheck(this, DeviceMessagesView);
      console.debug('Updating devices:', deviceList);
      await this.ensureRendered();
      ref = deviceList != null ? deviceList : [];
      for (i = 0, len = ref.length; i < len; i++) {
        device = ref[i];
        if (device.uuid === this.deviceUuid) {
          this.ui.nameLabel.text(device.name);
          return;
        }
      }
      return this.ui.nameLabel.text(DEFAULT_DEVICE_NAME);
    }

    async updateStateDisplay(stateList) {
      boundMethodCheck(this, DeviceMessagesView);
      console.debug('Updating state:', stateList);
      await this.ensureRendered();
      if (_.includes(stateList, this.deviceUuid)) {
        return this.ui.state.addClass(CONNECTED_CLASS);
      } else {
        return this.ui.state.removeClass(CONNECTED_CLASS);
      }
    }

    async handleMessageReceived(msg, ...args) {
      boundMethodCheck(this, DeviceMessagesView);
      console.debug('Received device message:', msg, ...args);
      await this.ensureRendered();
      return this.messagingConsole.addInboundMessage(formatMessage(msg, args));
    }

  };

  DeviceMessagesView.prototype.template = template;

  DeviceMessagesView.prototype.className = 'deviceMessages container';

  DeviceMessagesView.prototype.regions = {
    messageRegion: '#messageRegion'
  };

  DeviceMessagesView.prototype.ui = {
    nameLabel: '.deviceName',
    state: '.state',
    stateLabel: '.stateLabel'
  };

  return DeviceMessagesView;

}).call(this);
