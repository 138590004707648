var AVAILABLE_PLAYER_COUNTS, DISABLED_ATTR, SESSION_LIST_CHANGE_EVENT, TestModeView, debug;

import Marionette from 'backbone.marionette';

import Backbone from 'backbone';

import template from 'templates/gamemaster/testMode';

import _ from 'lodash';

import Toastr from 'toastr';

import DevicePickerModal from 'views/common/devicePickerModal';

import Modal from 'lib/modal';

import Socket from 'lib/socket';

import SocketUtils from 'lib/socket/utils';

import Language from 'lib/language';

import TestModeMonitor from 'views/gamemaster/testMode/monitor';

import Debug from 'debug';

debug = Debug('happinov:views:gamemaster:testmode');

DISABLED_ATTR = 'disabled';

AVAILABLE_PLAYER_COUNTS = [2, 3, 4, 5, 6];

SESSION_LIST_CHANGE_EVENT = 'session:list:change';

export default TestModeView = (function() {
  class TestModeView extends Marionette.CollectionView {
    async initialize() {
      debug('Initializing testMode view:', this.options);
      this.collection = new Backbone.Collection();
      this.model = new Backbone.Model({
        duration: 10,
        language: 'fr',
        players: '4'
      });
      this.model.on('change:devices', () => {
        return this.render();
      });
      this.model.on('change:roomId', () => {
        return this.setStartTestDisabledState();
      });
      SocketUtils.registerViewSubscription(this, `${SESSION_LIST_CHANGE_EVENT}`, this.fetchSessionList);
      await this.fetchGameMetadata();
      await this.fetchDeviceList();
      return (await this.fetchSessionList());
    }

    templateContext() {
      return {
        availablePlayerCounts: AVAILABLE_PLAYER_COUNTS,
        availableLanguages: function() {
          return _.map(Language.list(), function(lang) {
            lang.flag = Language.getFlag(lang.id);
            return lang;
          });
        }
      };
    }

    onRender() {
      this.setStartTestDisabledState();
      return this.stickit();
    }

    resetTestConfigurator() {
      return this.model.set({
        devices: [],
        roomId: '',
        duration: 10
      });
    }

    async fetchGameMetadata() {
      var availableRooms, err, id, roomDetails, rooms;
      try {
        // Fetch game zones, available zone durations and default settings
        rooms = (await Socket.emit('get:game:rooms'));
        console.debug('Game rooms fetched');
        availableRooms = [];
        for (id in rooms) {
          roomDetails = rooms[id];
          availableRooms.push({
            id: id,
            name: roomDetails.name
          });
        }
        this.model.set('availableRooms', availableRooms);
        return this.render();
      } catch (error) {
        err = error;
        return debug('Error fetching game metadata', err);
      }
    }

    async fetchSessionList() {
      var sessionList;
      sessionList = (await Socket.emit('session:list:started'));
      console.debug('Session list fetched', sessionList);
      return this.updateSessionList(sessionList);
    }

    updateSessionList(sessionList) {
      var err, hasSessionsRunning, testSessionList;
      try {
        hasSessionsRunning = (_.find(sessionList, function(session) {
          return !session.test_session;
        })) != null;
        this.model.set('hasSessionsRunning', hasSessionsRunning);
        testSessionList = _.filter(sessionList, function(session) {
          return session != null ? session.test_session : void 0;
        });
        console.debug('Updating test session list:', testSessionList);
        this.collection.set(testSessionList);
        return this.render();
      } catch (error) {
        err = error;
        return debug('Error fetching test sessions list', err);
      }
    }

    async fetchDeviceList() {
      var err;
      try {
        this.deviceList = (await Socket.emit('device:list'));
        return console.debug('Device list fetched', this.deviceList);
      } catch (error) {
        err = error;
        return debug('Error fetching device list', err);
      }
    }

    async setStartTestDisabledState() {
      var flag;
      flag = (_.isEmpty(this.model.get('roomId'))) || (_.isEmpty(this.model.get('devices')));
      await this.ensureRendered();
      if (flag) {
        return this.ui.startTestBtn.attr(DISABLED_ATTR, 'true');
      } else {
        return this.ui.startTestBtn.removeAttr(DISABLED_ATTR);
      }
    }

    selectDevice(event) {
      var currentSelection, view;
      currentSelection = _.map(this.model.get('devices'), 'uuid');
      view = new DevicePickerModal({
        currentSelection: currentSelection
      });
      view.on('submit', (selection) => {
        var err;
        debug('Device selection:', selection);
        if (selection !== currentSelection) {
          try {
            selection = _.map(selection, (uuid) => {
              return _.find(this.deviceList, function(device) {
                return device.uuid === uuid;
              });
            });
            return this.model.set('devices', selection);
          } catch (error) {
            err = error;
            console.error('Error setting selected devices:', err);
            return Toastr.error('Error setting selected devices.');
          }
        }
      });
      return Modal.show(view);
    }

    async startTest() {
      var err;
      try {
        await Socket.emit('test:session:start', {
          roomId: this.model.get('roomId'),
          devices: _.map(this.model.get('devices'), 'uuid'),
          language: this.model.get('language'),
          players: this.model.get('players'),
          duration: this.model.get('duration')
        });
        await this.fetchSessionList();
        return (await this.resetTestConfigurator());
      } catch (error) {
        err = error;
        Toastr.error('Error starting test:', err);
        return debug('Error starting test:', err);
      }
    }

  };

  TestModeView.prototype.template = template;

  TestModeView.prototype.className = 'testMode container';

  TestModeView.prototype.childView = TestModeMonitor;

  TestModeView.prototype.childViewContainer = '#monitorRegion';

  TestModeView.prototype.regions = {
    monitorRegion: '#monitorRegion'
  };

  TestModeView.prototype.ui = {
    selectDevice: '.selectDevice',
    startTestBtn: '.startTest'
  };

  TestModeView.prototype.events = {
    'click @ui.selectDevice': 'selectDevice',
    'click @ui.startTestBtn': 'startTest'
  };

  TestModeView.prototype.bindings = {
    '#roomSelector': 'roomId',
    '#durationInput': 'duration',
    '#languageSelector': 'language',
    '#playerCountSelector': 'players'
  };

  return TestModeView;

}).call(this);
