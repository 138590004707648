import * as Handlebars from 'handlebars/runtime';
import deviceTypeIcon from 'templates/partials/deviceTypeIcon';
import navbar from 'templates/partials/navbar';
Handlebars.registerHelper({});
Handlebars.registerPartial({deviceTypeIcon,navbar});
export default Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"teamDetails\" data-toggle=\"popover\" data-trigger=\"hover\" title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"globalScore") : depth0), depth0))
    + "\" data-content=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"scoreDetail") : depth0), depth0))
    + "\">\n	<span class=\"teamId faded teamDetail\">#"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "</span>\n	<span class=\"teamName teamDetail\"><b>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</b></span>\n	<span class=\"playerCount teamDetail\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"playerCount") : depth0), depth0))
    + " <i class=\"fas fa-male\"></i></span><span class=\"language teamDetail\"> "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"languageFlag") : depth0), depth0))
    + "</span>\n</div>\n";
},"useData":true});