var SessionCreateView, getDefaultSessionName;

import Marionette from 'backbone.marionette';

import template from 'templates/gamemaster/sessionCreate';

import Backbone from 'backbone';

import Toastr from 'toastr';

import Path from 'path';

import {
  sprintf
} from 'sprintf-js';

import Moment from 'moment';

import Config from 'etc/config';

import Socket from 'lib/socket';

import SettingsEditorView from 'views/gamemaster/sessionDetails/settingsEditor';

getDefaultSessionName = function() {
  return sprintf(Config.defaultSessionName, Moment().format('HH:mm'));
};

export default SessionCreateView = (function() {
  class SessionCreateView extends Marionette.View {
    initialize() {
      console.debug('Initializing SessionCreateView:', this.options);
      return this.model = new Backbone.Model({
        name: getDefaultSessionName()
      });
    }

    onRender() {
      this.settingsEditorView = new SettingsEditorView();
      this.showChildView('settingsRegion', this.settingsEditorView);
      return this.stickit();
    }

    async handleSubmit() {
      var data, err;
      data = {
        name: this.model.get('name'),
        game_settings: this.settingsEditorView.getSettings()
      };
      console.debug('Creating session with data:', data);
      try {
        await Socket.emit('session:create', data);
        Toastr.success('Session created.');
        return this.trigger('navigate', Path.dirname(this.options.currentPath));
      } catch (error) {
        err = error;
        return Toastr.error(`Error creating session: ${err}`);
      }
    }

  };

  SessionCreateView.prototype.template = template;

  SessionCreateView.prototype.className = 'sessionCreate container';

  SessionCreateView.prototype.regions = {
    settingsRegion: '#settingsRegion'
  };

  SessionCreateView.prototype.events = {
    'click #submitButton': 'handleSubmit'
  };

  SessionCreateView.prototype.bindings = {
    '#sessionNameInput': 'name'
  };

  return SessionCreateView;

}).call(this);
