var BackofficeApp, BackofficeRouter, LAYOUT_MAP, debug;

import {
  Application
} from 'backbone.marionette';

import Backbone from 'backbone';

import _ from 'lodash';

import Auth from 'lib/auth';

import Socket from 'lib/socket';

import LoginView from 'views/login';

import HomeView from 'views/home';

import CommonLayoutView from 'views/common/layout';

import AdminUserListView from 'views/admin/userList';

import AdminUserEditorView from 'views/admin/userEditor';

import AdminActionsView from 'views/admin/actions';

import SessionListView from 'views/gamemaster/sessionList';

import SessionCreateView from 'views/gamemaster/sessionCreate';

import SessionDetailsView from 'views/gamemaster/sessionDetails';

import DeviceListView from 'views/gamemaster/deviceList';

import MonitorView from 'views/gamemaster/monitor';

import DeviceMessagesView from 'views/gamemaster/deviceMessages';

import ManageGameView from 'views/gamemaster/manageGame';

import TestModeView from 'views/gamemaster/testMode';

import Debug from 'debug';

debug = Debug('happinov:app');

LAYOUT_MAP = {
  admin: {
    defaultView: 'users',
    navItems: [
      {
        label: 'Users',
        link: ''
      },
      {
        label: 'Actions',
        link: 'actions'
      }
    ],
    views: {
      users: {
        class: AdminUserListView,
        subview: AdminUserEditorView
      },
      actions: {
        class: AdminActionsView
      }
    }
  },
  gamemaster: {
    defaultView: 'sessions',
    navItems: [
      {
        label: 'Sessions',
        link: ''
      },
      {
        label: 'Monitor',
        link: 'monitor'
      },
      {
        label: 'Devices',
        link: 'devices'
      },
      {
        label: 'Game',
        link: 'game'
      },
      {
        label: 'Test Mode',
        link: 'testmode'
      }
    ],
    views: {
      sessions: {
        class: SessionListView,
        subview: SessionDetailsView
      },
      sessioncreate: {
        class: SessionCreateView
      },
      monitor: {
        class: MonitorView
      },
      devices: {
        class: DeviceListView,
        subview: DeviceMessagesView
      },
      game: {
        class: ManageGameView
      },
      testmode: {
        class: TestModeView
      }
    }
  }
};

BackofficeRouter = (function() {
  class BackofficeRouter extends Backbone.Router {
    initialize(options1) {
      this.options = options1;
      console.debug('Initializing BackofficeRouter:', this.options);
      ({showView: this.showView, getCurrentView: this.getCurrentView} = this.options);
      Auth.on('logout', () => {
        return this.redirectToLogin();
      });
      return Auth.on('login', () => {
        return this.redirectHome();
      });
    }

    navigateTrigger(route, options) {
      return this.navigate(route, _.assign({}, options, {
        trigger: true
      }));
    }

    redirectToLogin() {
      return this.navigateTrigger('login');
    }

    redirectHome() {
      console.debug('Redirecting to root.');
      return this.navigateTrigger('home');
    }

    checkLoggedIn(callback) {
      if (Auth.isLoggedIn()) {
        return callback();
      } else {
        console.debug('User not logged in, redirecting to login form.');
        return this.redirectToLogin();
      }
    }

    processLayout(section, subpath) {
      return this.checkLoggedIn(() => {
        var currentView, layoutClass, ref, subpathItems, view;
        if (LAYOUT_MAP[section] != null) {
          layoutClass = (ref = LAYOUT_MAP[section].class) != null ? ref : CommonLayoutView;
          currentView = this.getCurrentView();
          subpathItems = subpath != null ? subpath.split('/') : [];
          if (currentView instanceof layoutClass && (typeof currentView.getSection === "function" ? currentView.getSection() : void 0) === section) {
            // keep existing view.
            return currentView.updateSubpath(subpathItems);
          } else {
            view = new layoutClass(_.assign({}, LAYOUT_MAP[section], {
              section: section,
              subpath: subpathItems
            }));
            view.on('navigate', (uri) => {
              return this.navigateTrigger(uri);
            });
            return this.showView(view);
          }
        } else {
          return this.redirectHome();
        }
      });
    }

    showLogin() {
      var view;
      if (Auth.isLoggedIn()) {
        console.debug('Preventing logged in user to use the login form.');
        return this.redirectHome();
      } else {
        view = new LoginView();
        return this.showView(view);
      }
    }

    showHome() {
      return this.checkLoggedIn(() => {
        var view;
        view = new HomeView();
        return this.showView(view);
      });
    }

  };

  BackofficeRouter.prototype.routes = {
    'login': 'showLogin',
    'home': 'showHome',
    ':section(/*subpath)': 'processLayout',
    '*uri': 'redirectHome' // catch all.
  };

  return BackofficeRouter;

}).call(this);

export default BackofficeApp = class BackofficeApp extends Application {
  initialize() {
    return console.debug('Initializing application:', this.options);
  }

  onStart() {
    var err;
    try {
      Auth.initialize(Socket);
      Socket.connect('/backoffice');
      Socket.on('connect', function() {
        return debug('Socket connected!');
      });
      return Socket.once('welcome', (welcomeData) => {
        debug('Welcome received:', welcomeData);
        if ((welcomeData != null ? welcomeData.currentUser : void 0) != null) {
          console.debug('User is connected:', welcomeData.currentUser);
          Auth.setUser(welcomeData.currentUser);
        }
        this.router = new BackofficeRouter({
          showView: (view) => {
            return this.showView(view);
          },
          getCurrentView: () => {
            return this.getRegion().currentView;
          }
        });
        if (!Backbone.history.start()) {
          throw new Error('Backbone history could not start.');
        }
      });
    } catch (error) {
      err = error;
      document.querySelector(this.region).innerHTML = `Error: ${err.message}`;
      return console.error(err);
    }
  }

};
