import Marionette from 'backbone.marionette'
import template from 'templates/common/layout'

import _ from 'lodash'
import Path from 'path'

import AppMetadata from 'metadata'
import Auth from 'lib/auth'
import SocketUtils from 'lib/socket/utils'
import Socket from 'lib/socket'
import Toastr from 'toastr'

import BannerView from 'views/common/banner'

const NAVIGATE_EVENT = 'navigate'
const ACTIVE_CLASS = 'active'

const EXTERNAL_SERVER_STATE_EVENT = 'external:server:state'
const EXTERNAL_SERVER_STATE_CHANGE_EVENT = 'external:server:state:change'

const CONNECTED_STATUS = 'connected'

const CommonLayoutView = Marionette.View.extend({
	template: template,

	regions: {
		mainRegion: '#mainRegion',
		bannerRegion: '#bannerRegion',
	},

	ui: {
		tabs: '.navbar .navbar-nav .nav-link',
	},

	events: {
		'click #logoutButton': 'logout',
	},

	className: function() {
		return `layout ${this.options.section}`
	},

	async initialize() {
		console.debug('Initializing CommonLayoutView:',this.options)

		// defer subpath processing to let parent register event listener.
		setTimeout(()=> {
			this.updateSubpath(this.options.subpath)
		},0)

		this.currentExternalServerState = null
		SocketUtils.registerViewSubscription(this,EXTERNAL_SERVER_STATE_CHANGE_EVENT,this.updateBanner)
		await this.refreshExternalServerState()
	},

	formatNavItems(items) {
		return items.map((item)=> {
			return _.assign({},item,{
				fullLink: Path.join(this.getSection(),item.link),
			})
		})
	},

	templateContext() {
		return {
			AppMetadata: AppMetadata,
			User: Auth.getUser(),
			NavItems: this.formatNavItems(this.options.navItems),
			banner: this.banner,
		}
	},

	showView(view) {
		view.on('navigate', (...args)=> this.trigger('navigate',...args))
		this.showChildView('mainRegion',view)
	},

	logout() {
		Auth.logout()
	},

	getSection() {
		return this.options.section
	},

	async updateActiveTab(tabName, isDefault) {
		await this.ensureRendered()
		this.ui.tabs.removeClass(ACTIVE_CLASS)

		this.ui.tabs.each((index,el)=> {
			if (el.dataset.link == tabName || isDefault && !el.dataset.link)
				el.classList.add(ACTIVE_CLASS)
		})
	},

	updateSubpath(subpathItems) {
		console.debug('Processing subpath:',subpathItems)
		const currentTab = _.head(subpathItems) || this.options.defaultView
		const remainingPath = _.tail(subpathItems)
		console.debug('Current tab:',currentTab,remainingPath)

		if (this.options.views[currentTab]) {
			this.updateActiveTab(currentTab, currentTab == this.options.defaultView)
			const hasRemainingPath = !_.isEmpty(remainingPath)

			if (hasRemainingPath && !this.options.views[currentTab].subview) {
				this.trigger(NAVIGATE_EVENT, `\#${currentTab}`)
			} else {
				let viewClass

				if (hasRemainingPath && this.options.views[currentTab].subview)
					viewClass = this.options.views[currentTab].subview
				else
					viewClass = this.options.views[currentTab].class

				const view = new viewClass({
					remainingPath: remainingPath,
					currentPath: Path.join(this.getSection(),currentTab),
				})

				view.on(NAVIGATE_EVENT, (target)=> {
					this.trigger(NAVIGATE_EVENT,target)
				})

				this.showView(view)
			}
		} else {
			// clearing unknown subpath.
			this.trigger(NAVIGATE_EVENT, Path.join(this.getSection()))
		}
	},

	async refreshExternalServerState() {
		try {
			const extServerStatus = await Socket.emit(EXTERNAL_SERVER_STATE_EVENT)
			this.updateBanner(extServerStatus)
		} catch(err) {
			console.error('Error fetching external server status:',err)
			Toastr.error(`Error fetching external server status: ${err}`)
		}
	},

	updateBanner(status) {
		if (status == this.currentExternalServerState)
			return // Do nothing

		this.currentExternalServerState = status

		switch(status) {
			case CONNECTED_STATUS:
				this.emptyBannerRegion()
				break

			default:
				this.showBanner({
					classes: 'bg-danger text-white',
					message: `Game can\'t be operated: Eludice external server manager status: ${status}.`,
				})
		}
	},

	emptyBannerRegion() {
		this.getRegion('bannerRegion').empty()
	},

	showBanner(bannerDetails) {
		const bannerView = new BannerView(bannerDetails)
		this.showChildView('bannerRegion', bannerView)
	},
})

export default CommonLayoutView
