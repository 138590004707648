var ERROR_MAP, LoginView;

import Marionette from 'backbone.marionette';

import template from 'templates/login';

import AppMetadata from 'metadata';

import Auth from 'lib/auth';

ERROR_MAP = {
  ERROR: 'Server Error.',
  AUTHENTICATION_FAILED: 'Invalid login or password.'
};

export default LoginView = (function() {
  class LoginView extends Marionette.View {
    initialize() {
      return console.debug('Initializing LoginView:', this.options);
    }

    templateContext() {
      return {
        AppMetadata: AppMetadata
      };
    }

    handleFormSubmit(event) {
      console.debug('Form submitted');
      this.ui.messageZone.empty();
      return this.ui.iframe.one('load', async() => {
        var err, ref;
        try {
          return (await Auth.login(this.ui.usernameInput.val(), this.ui.passwordInput.val()));
        } catch (error) {
          err = error;
          console.error('Login error:', err);
          this.ui.messageZone.text((ref = ERROR_MAP[err]) != null ? ref : err);
          return this.ui.passwordInput.focus().select();
        }
      });
    }

  };

  LoginView.prototype.template = template;

  LoginView.prototype.className = 'login';

  LoginView.prototype.ui = {
    usernameInput: '#usernameInput',
    passwordInput: '#passwordInput',
    messageZone: '.messageZone',
    iframe: 'iframe'
  };

  LoginView.prototype.events = {
    'submit form': 'handleFormSubmit'
  };

  return LoginView;

}).call(this);
