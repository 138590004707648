var CONNECTED_CLASS, DeviceListView, getTargetUuid,
  boundMethodCheck = function(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new Error('Bound instance method accessed before binding'); } };

import Marionette from 'backbone.marionette';

import template from 'templates/gamemaster/deviceList';

import Toastr from 'toastr';

import _ from 'lodash';

import Moment from 'moment';

import Socket from 'lib/socket';

import SocketUtils from 'lib/socket/utils';

import DeviceName from 'lib/deviceName';

CONNECTED_CLASS = 'connected';

getTargetUuid = function(event) {
  return event.currentTarget.dataset.uuid;
};

export default DeviceListView = (function() {
  class DeviceListView extends Marionette.View {
    constructor() {
      super(...arguments);
      this.updateDeviceList = this.updateDeviceList.bind(this);
      this.updateDisplayedStates = this.updateDisplayedStates.bind(this);
    }

    async initialize() {
      console.debug('Initializing DeviceListView:', this.options);
      return (await SocketUtils.registerDeviceSubscriptions(this, this.updateDeviceList, this.updateDisplayedStates, function(err) {
        console.error(err);
        return Toastr.error(`Error listing devices: ${err}`);
      }));
    }

    serializeData() {
      return {
        devices: _.isEmpty(this.devices) ? null : this.devices
      };
    }

    onRender() {
      return this.ui.resetButton.enableLongpress(2);
    }

    parseResult(list) {
      if (list == null) {
        list = [];
      }
      list.forEach((item) => {
        item.sortName = item.name.toLowerCase();
        item.modelName = DeviceName.lookup(item.manufacturer, item.model);
        return item.messageLink = `\#${this.options.currentPath}/${item.uuid}`;
      });
      list = _.sortBy(list, 'sortName');
      return list;
    }

    updateDeviceList(list) {
      boundMethodCheck(this, DeviceListView);
      this.devices = this.parseResult(list);
      return this.render();
    }

    async updateDisplayedStates(connectedUuids) {
      boundMethodCheck(this, DeviceListView);
      console.debug('Updating device states:', connectedUuids);
      await this.ensureRendered();
      return this.ui.devices.each(function(index, el) {
        if (_.includes(connectedUuids, el.dataset.uuid)) {
          return el.classList.add(CONNECTED_CLASS);
        } else {
          return el.classList.remove(CONNECTED_CLASS);
        }
      });
    }

    async handleRename(event) {
      var currentName, currentTarget, err, newName;
      currentTarget = event.currentTarget;
      currentName = currentTarget.dataset.name;
      newName = prompt('Please type in the new device name.', currentName);
      if (newName) {
        newName = newName.trim();
        if (!(_.isEmpty(newName) || newName === currentName)) {
          try {
            await Socket.emit('device:rename', getTargetUuid(event), newName);
            return Toastr.success('Device renamed.');
          } catch (error) {
            err = error;
            return Toastr.error(`Error renaming device: ${err}`);
          }
        }
      }
    }

    // don't refresh, subscription will do it for us.
    async handleDelete(event) {
      var err;
      if (confirm(`Do you want to delete \"${event.currentTarget.dataset.name}\" ?`)) {
        try {
          await Socket.emit('device:delete', getTargetUuid(event));
          return Toastr.success('Device deleted.');
        } catch (error) {
          err = error;
          return Toastr.error(`Error deleting device: ${err}`);
        }
      }
    }

    // don't refresh, subscription will do it for us.
    async handleFind(event) {
      var err;
      try {
        return (await Socket.emit('device:message:send', getTargetUuid(event), 'find'));
      } catch (error) {
        err = error;
        return Toastr.error(`Error trying to find device: ${err}`);
      }
    }

    async handleReset(event) {
      var err;
      try {
        return (await Socket.emit('device:reset', getTargetUuid(event)));
      } catch (error) {
        err = error;
        return Toastr.error(`Error trying to reset device: ${err}`);
      }
    }

  };

  DeviceListView.prototype.template = template;

  DeviceListView.prototype.className = 'deviceList container';

  DeviceListView.prototype.ui = {
    devices: '.device',
    resetButton: '.resetButton'
  };

  DeviceListView.prototype.events = {
    'click .renameButton': 'handleRename',
    'click .deleteButton': 'handleDelete',
    'click .findButton': 'handleFind',
    'longpress .resetButton': 'handleReset'
  };

  return DeviceListView;

}).call(this);
