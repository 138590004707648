var ACTIVE_CLASS, CONNECTED_CLASS, DISABLED_ATTR, DeviceSelectorModalView, DeviceView,
  boundMethodCheck = function(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new Error('Bound instance method accessed before binding'); } };

import Marionette from 'backbone.marionette';

import template from 'templates/common/deviceSelectorModal';

import itemTemplate from 'templates/common/deviceSelectorModal/item';

import _ from 'lodash';

import Backbone from 'backbone';

import Toastr from 'toastr';

import Socket from 'lib/socket';

import SocketUtils from 'lib/socket/utils';

import DeviceName from 'lib/deviceName';

CONNECTED_CLASS = 'connected';

DISABLED_ATTR = 'disabled';

ACTIVE_CLASS = 'active';

DeviceView = (function() {
  class DeviceView extends Marionette.View {
    initialize() {
      return console.debug('Initializing DeviceView:', this.options);
    }

    onRender() {
      if (this.model.get('is_active')) {
        console.log('>> Adding active class');
        return this.$el.addClass(ACTIVE_CLASS);
      }
    }

    isSelected() {
      return this.ui.checkbox.prop('checked');
    }

    setSelected(bool) {
      if (bool) {
        return this.ui.checkbox.prop('checked', true);
      } else {
        return this.ui.checkbox.prop('checked', false);
      }
    }

    async setConnected(flag) {
      await this.ensureRendered();
      if (flag) {
        return this.$el.addClass(CONNECTED_CLASS);
      } else {
        return this.$el.removeClass(CONNECTED_CLASS);
      }
    }

    async setActive(flag) {
      await this.ensureRendered();
      if (flag) {
        return this.$el.addClass(ACTIVE_CLASS);
      } else {
        return this.$el.removeClass(ACTIVE_CLASS);
      }
    }

    getUuid() {
      return this.model.get('uuid');
    }

    getType() {
      return this.model.get('type');
    }

    getManufacturer() {
      return this.model.get('manufacturer');
    }

    getModel() {
      return this.model.get('model');
    }

    templateContext() {
      return {
        modelName: DeviceName.lookup(this.getManufacturer(), this.getModel())
      };
    }

  };

  DeviceView.prototype.template = itemTemplate;

  DeviceView.prototype.className = 'device';

  DeviceView.prototype.triggers = {
    'change @ui.checkbox': 'change'
  };

  DeviceView.prototype.ui = {
    checkbox: 'input'
  };

  return DeviceView;

}).call(this);

export default DeviceSelectorModalView = (function() {
  class DeviceSelectorModalView extends Marionette.CollectionView {
    constructor() {
      super(...arguments);
      this.updateDeviceList = this.updateDeviceList.bind(this);
    }

    initialize() {
      console.debug('Initializing DeviceSelectorModalView:', this.options);
      ({currentSelection: this.currentSelection, unavailableDevices: this.unavailableDevices} = this.options);
      this.collection = new Backbone.Collection();
      this.collection.comparator = 'sortName';
      return SocketUtils.registerDeviceSubscriptions(this, this.updateDeviceList, this.updateDisplayedStates, function(err) {
        console.error(err);
        return Toastr.error(`Echec du listage des appareils : ${err}`);
      });
    }

    viewFilter(childView) {
      return !this.unavailableDevices.includes(childView.getUuid());
    }

    parseList(list) {
      list.forEach(function(item) {
        item.id = item.uuid;
        return item.sortName = item.name.toLowerCase();
      });
      return list;
    }

    updateDeviceList(list) {
      boundMethodCheck(this, DeviceSelectorModalView);
      console.debug('Received list:', list);
      this.collection.reset(this.parseList(list));
      return this.children.each((item) => {
        return item.setSelected(this.currentSelection.includes(item.getUuid()));
      });
    }

    updateDisplayedStates(states) {
      return this.children.each(function(item) {
        return item.setConnected(_.includes(states, item.getUuid()));
      });
    }

    getSelection() {
      var selection;
      selection = [];
      this.children.each(function(item) {
        if (item.isSelected()) {
          return selection.push(item.getUuid());
        }
      });
      return selection;
    }

    handleSubmit() {
      this.trigger('submit', this.getSelection());
      return this.trigger('modal:close'); // don't close before getting current selection.
    }

  };

  DeviceSelectorModalView.prototype.template = template;

  DeviceSelectorModalView.prototype.className = 'deviceSelector modal-dialog modal-dialog-centered modal-dialog-scrollable';

  DeviceSelectorModalView.prototype.childView = DeviceView;

  DeviceSelectorModalView.prototype.childViewContainer = '.modal-body';

  DeviceSelectorModalView.prototype.ui = {
    submitButton: '.submitButton'
  };

  DeviceSelectorModalView.prototype.events = {
    'click .submitButton': 'handleSubmit'
  };

  return DeviceSelectorModalView;

}).call(this);
