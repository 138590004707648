var DISABLED_CLASS, END_SEQUENCE_ID, RESET_GAME_STEP_EVENT, RESTART_ROOM_EVENT, ROOM_REPLACE_EVENT, RoomView, debug;

import Backbone from 'backbone';

import Marionette from 'backbone.marionette';

import template from 'templates/gamemaster/monitor/room';

import TeamView from 'views/gamemaster/monitor/team';

import Socket from 'lib/socket';

import SocketUtils from 'lib/socket/utils';

import Toastr from 'toastr';

import Swal from 'lib/swal';

import Debug from 'debug';

debug = Debug('happinov:gamemaster:monitor:room');

RESET_GAME_STEP_EVENT = 'reset:game:step';

RESTART_ROOM_EVENT = 'restart:game:room';

ROOM_REPLACE_EVENT = 'replace:game:room';

DISABLED_CLASS = 'disabled';

END_SEQUENCE_ID = 'end_sequence';

export default RoomView = (function() {
  class RoomView extends Marionette.CollectionView {
    initialize() {
      debug('Initializing roomView:', this.options);
      this.collection = new Backbone.Collection();
      return this.refreshRoomTeam();
    }

    templateContext() {
      return {
        hasTeam: this.collection.length > 0
      };
    }

    async refreshRoomTeam() {
      var err, roomTeams;
      try {
        roomTeams = (await Socket.emit('room:team', this.model.get('id')));
        return this.updateRoomTeams(roomTeams);
      } catch (error) {
        err = error;
        debug('Error fetching room team');
        return Toastr.error(`Error fetching room team: ${err}`);
      }
    }

    async updateRoomTeams(teams) {
      this.teams = teams;
      await this.ensureRendered();
      this.collection.reset(this.teams);
      if (this.collection.length > 0 && this.model.get('id') !== END_SEQUENCE_ID) {
        this.ui.resetStepButton.removeClass(DISABLED_CLASS);
        return this.ui.restartGameButton.removeClass(DISABLED_CLASS);
      } else {
        this.ui.resetStepButton.addClass(DISABLED_CLASS);
        return this.ui.restartGameButton.addClass(DISABLED_CLASS);
      }
    }

    handleResetStep() {
      return Swal.confirm(`Réinitialiser l'étape ${this.model.get('name')} ?`, () => {
        var err;
        try {
          this.teams.forEach(async function(team) {
            // in case multiple teams in step (end_sequence)
            return (await Socket.emit(RESET_GAME_STEP_EVENT, team.id));
          });
          return Toastr.success(`Game step reset: ${this.model.get('name')}`);
        } catch (error) {
          err = error;
          debug('Error resetting game step:', err);
          return Toastr.error('Error resetting game step.');
        }
      });
    }

    handleRestartGame() {
      return Swal.confirm(`Redémarrer le jeu ${this.model.get('name')} ?`, async() => {
        var err;
        try {
          await Socket.emit(RESTART_ROOM_EVENT, this.model.get('id'));
          return Toastr.success(`Room restarted: ${this.model.get('name')}`);
        } catch (error) {
          err = error;
          debug('Error restarting room:', err);
          return Toastr.error('Error restarting room.');
        }
      });
    }

    async handleReplace() {
      var defaultValue, id, inputOptions, message, room, rooms, title;
      rooms = (await Socket.emit('get:game:rooms'));
      for (id in rooms) {
        room = rooms[id];
        rooms[id] = room.name;
      }
      inputOptions = rooms;
      defaultValue = this.model.get('id');
      title = `Remplacer ${this.model.get('name')}?`;
      message = "";
      return Swal.promptSelect(message, title, inputOptions, defaultValue, async(selection) => {
        var err;
        try {
          await Socket.emit(ROOM_REPLACE_EVENT, this.model.get('id'), selection);
          return Toastr.success(`Room replaced: ${this.model.get('id')} <-> ${selection}`);
        } catch (error) {
          err = error;
          debug('Error replacing room:', err);
          return Toastr.error('Error replacing room.');
        }
      });
    }

  };

  RoomView.prototype.template = template;

  RoomView.prototype.className = 'room card';

  RoomView.prototype.childView = TeamView;

  RoomView.prototype.childViewContainer = '.teams';

  RoomView.prototype.ui = {
    resetStepButton: '.resetStepButton',
    restartGameButton: '.restartGameButton',
    replaceButton: '.replaceButton'
  };

  RoomView.prototype.events = {
    'click @ui.resetStepButton': 'handleResetStep',
    'click @ui.restartGameButton': 'handleRestartGame',
    'click @ui.replaceButton': 'handleReplace'
  };

  return RoomView;

}).call(this);
