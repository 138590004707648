var FileUtils;

import $ from 'jquery';

export default FileUtils = {
  forceDownload: function(blob, filename) {
    var el;
    el = document.createElement('a');
    el.setAttribute('href', URL.createObjectURL(blob));
    el.setAttribute('download', filename);
    el.style.display = 'none';
    document.body.appendChild(el);
    el.click();
    return document.body.removeChild(el);
  },
  forceDataDownload: function(data, filename) {
    var blob;
    blob = new Blob([data], {
      type: 'text/plain'
    });
    return FileUtils.forceDownload(blob, filename);
  },
  selectFile: function(acceptedFileExtensions) {
    return new Promise(function(resolve, reject) {
      var el;
      el = document.createElement('input');
      el.setAttribute('type', 'file');
      if (acceptedFileExtensions != null) {
        el.setAttribute('accept', acceptedFileExtensions);
      }
      el.click();
      return el.oninput = function() {
        if (el.files.length === 1) {
          return resolve(el.files[0]);
        } else {
          return reject(new Error("No file selected."));
        }
      };
    });
  },
  upload: function(serviceUrl, data) {
    return new Promise(function(resolve, reject) {
      var formData, key, val;
      formData = new FormData();
      for (key in data) {
        val = data[key];
        formData.append(key, val);
      }
      return $.ajax({
        url: serviceUrl,
        type: 'POST',
        cache: false,
        data: formData,
        processData: false, // prevent jquery from trying to interpret formdata object
        contentType: false, // let jquery set content type.
        success: function(data, textStatus, jqXHR) {
          if (data.error) {
            return reject(data.error);
          } else {
            return resolve(data);
          }
        },
        error: function(jqXHR, textStatus, errorThrown) {
          var ref;
          console.warn('Upload request error:', jqXHR, textStatus, errorThrown);
          return reject(new Error((ref = jqXHR.responseText) != null ? ref : errorThrown));
        }
      });
    });
  }
};
