import * as Handlebars from 'handlebars/runtime';
import deviceTypeIcon from 'templates/partials/deviceTypeIcon';
import navbar from 'templates/partials/navbar';
Handlebars.registerHelper({});
Handlebars.registerPartial({deviceTypeIcon,navbar});
export default Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<div class=\"sessionLabel\">\n			<span class=\"sessionId\">#"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"session") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "</span> <span class=\"sessionName\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"session") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span>\n			<a classes=\"btn btn-primary btn-sm\" href=\"#gamemaster/sessions/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"session") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"><i class=\"far fa-link\"></i></a>\n		</div>\n		<div class=\"currentStep\">Étape #"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"session") : depth0)) != null ? lookupProperty(stack1,"current_state") : stack1)) != null ? lookupProperty(stack1,"step") : stack1), depth0))
    + " ->\n			<span class=\"currentStepTime\">Fin à "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"session") : depth0)) != null ? lookupProperty(stack1,"current_state") : stack1)) != null ? lookupProperty(stack1,"stepEndTime") : stack1), depth0))
    + "</span>\n		</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"session") : depth0)) != null ? lookupProperty(stack1,"current_state") : stack1)) != null ? lookupProperty(stack1,"nextZoneName") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":2},"end":{"line":17,"column":9}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<div class=\"nextZone\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"session") : depth0)) != null ? lookupProperty(stack1,"current_state") : stack1)) != null ? lookupProperty(stack1,"nextZoneName") : stack1), depth0))
    + " ->\n			<span class=\"nextZoneTime\"></span>Début à "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"session") : depth0)) != null ? lookupProperty(stack1,"current_state") : stack1)) != null ? lookupProperty(stack1,"zoneEndTime") : stack1), depth0))
    + "\n		</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "		<div class=\"message\">PAS DE SESSION EN COURS</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"zoneName card-header\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</div>\n<div class=\"zoneDetails card-body\">\n	<div class=\"sessionDetails\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"session") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":20,"column":9}}})) != null ? stack1 : "")
    + "	</div>\n	<div class=\"rooms\"></div>\n</div>\n";
},"useData":true});