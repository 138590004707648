var AdminActionsView;

import Marionette from 'backbone.marionette';

import template from 'templates/admin/actions';

import Socket from 'lib/socket';

import Toastr from 'toastr';

export default AdminActionsView = (function() {
  class AdminActionsView extends Marionette.View {
    initialize() {
      return console.debug('Initializing AdminActionsView:', this.options);
    }

  };

  AdminActionsView.prototype.template = template;

  AdminActionsView.prototype.className = 'actions container';

  return AdminActionsView;

}).call(this);
