import * as Handlebars from 'handlebars/runtime';
import deviceTypeIcon from 'templates/partials/deviceTypeIcon';
import navbar from 'templates/partials/navbar';
Handlebars.registerHelper({});
Handlebars.registerPartial({deviceTypeIcon,navbar});
export default Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "	<h4 class=\"faded\">Test mode not available when sessions are started.</h4>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<div class=\"testSettingsContainer card mb-3\">\n		<div class=\"card-body d-flex flex-column\">\n			<form class=\"form-inline\">\n				<label for=\"roomSelector\">Game: </label>\n				<select id=\"roomSelector\" name=\"roomSelector\" class=\"align-self-start form-control w-auto\">\n					<option value=\"\">-- Select a room --</option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"availableRooms") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":5},"end":{"line":13,"column":14}}})) != null ? stack1 : "")
    + "				</select>\n			</form>\n			<form class=\"form-inline\">\n				<label>Devices:</label>\n				<button class=\"btn btn-secondary align-self-start selectDevice\"><i class=\"far fa-plus\"></i> Select</button>\n				<div class=\"deviceList\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"devices") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":20,"column":5},"end":{"line":24,"column":59}}})) != null ? stack1 : "")
    + "\n				</div>\n			</form>\n			<form class=\"form-inline\">\n				<label for=\"playerCountSelector\">Players:</label>\n				<select id=\"playerCountSelector\" name=\"playerCountSelector\" class=\"align-self-start form-control w-auto\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"availablePlayerCounts") : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":5},"end":{"line":32,"column":14}}})) != null ? stack1 : "")
    + "				</select>\n			</form>\n			<form class=\"form-inline\">\n				<label for=\"languageSelector\">Language:</label>\n				<select id=\"languageSelector\" name=\"languageSelector\" class=\"align-self-start form-control w-auto\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"availableLanguages") : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":5},"end":{"line":40,"column":14}}})) != null ? stack1 : "")
    + "				</select>\n			</form>\n			</form>\n			<form class=\"form-inline\">\n				<label for=\"durationInput\">Duration:</label>\n				<input id=\"durationInput\" name=\"durationInput\" class=\"align-self-start form-control w-auto\" type=\"number\" min=\"0\"></input>\n			</form>\n			<button class=\"btn btn-primary align-self-end startTest\"><i class=\"far fa-plus\"></i> Start test</button>\n		</div>\n	</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</option>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"devices") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":5},"end":{"line":23,"column":14}}})) != null ? stack1 : "")
    + "					";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<div class=\"device btn btn-outline-secondary disabled\"><i class=\"fas fa-tablet-alt\"></i> "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "<i class=\"faded\">No device selected</i>";
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "					<option value=\""
    + alias2(alias1(depth0, depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "</option>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"flag") : depth0), depth0))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1>Test Mode</h1>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasSessionsRunning") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":51,"column":7}}})) != null ? stack1 : "")
    + "<div id=\"monitorRegion\"></div>\n";
},"useData":true});