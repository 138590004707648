var CONNECTED_STATUS, EXTERNAL_SERVER_STATE_CHANGE_EVENT, EXTERNAL_SERVER_STATE_EVENT, HomeView;

import Marionette from 'backbone.marionette';

import template from 'templates/home';

import AppMetadata from 'metadata';

import SocketUtils from 'lib/socket/utils';

import Socket from 'lib/socket';

import Toastr from 'toastr';

import BannerView from 'views/common/banner';

import Auth from 'lib/auth';

EXTERNAL_SERVER_STATE_EVENT = 'external:server:state';

EXTERNAL_SERVER_STATE_CHANGE_EVENT = 'external:server:state:change';

CONNECTED_STATUS = 'connected';

export default HomeView = (function() {
  class HomeView extends Marionette.View {
    async initialize() {
      console.debug('Initializing HomeView:', this.options);
      SocketUtils.registerViewSubscription(this, EXTERNAL_SERVER_STATE_CHANGE_EVENT, this.updateBanner);
      return (await this.refreshExternalServerState());
    }

    templateContext() {
      return {
        AppMetadata: AppMetadata,
        User: Auth.getUser(),
        banner: this.banner
      };
    }

    showView(view) {
      return this.showChildView('mainRegion');
    }

    logout() {
      return Auth.logout();
    }

    async refreshExternalServerState() {
      var err, extServerStatus;
      try {
        extServerStatus = (await Socket.emit(EXTERNAL_SERVER_STATE_EVENT));
        return this.updateBanner(extServerStatus);
      } catch (error) {
        err = error;
        console.error('Error fetching external server status:', err);
        return Toastr.error(`Error fetching external server status: ${err}`);
      }
    }

    updateBanner(status) {
      switch (status) {
        case CONNECTED_STATUS:
          return this.emptyBannerRegion();
        default:
          return this.showBanner({
            classes: "bg-danger text-white",
            message: `Game can\'t be operated: Eludice external server manager status: ${status}.`
          });
      }
    }

    emptyBannerRegion() {
      return this.getRegion('bannerRegion').empty();
    }

    showBanner(bannerDetails) {
      var bannerView;
      bannerView = new BannerView(bannerDetails);
      return this.showChildView('bannerRegion', bannerView);
    }

  };

  HomeView.prototype.template = template;

  HomeView.prototype.className = 'home layout';

  HomeView.prototype.regions = {
    mainRegion: '#mainRegion',
    bannerRegion: '#bannerRegion'
  };

  HomeView.prototype.events = {
    'click #logoutButton': 'logout'
  };

  return HomeView;

}).call(this);
