var Auth, LOGIN_EVENT, LOGOUT_EVENT, _currentUser, _socket;

import {
  Events
} from 'backbone';

import _ from 'lodash';

LOGIN_EVENT = 'login';

LOGOUT_EVENT = 'logout';

_currentUser = null;

_socket = {
  emit: function() {
    throw new Error('Socket has not been properly initialized.');
  }
};

Auth = {
  initialize: function(socket) {
    return _socket = socket;
  },
  setUser: function(newUser) {
    _currentUser = newUser;
    return Auth.trigger(LOGIN_EVENT, _currentUser);
  },
  getUser: function() {
    return _currentUser;
  },
  isLoggedIn: function() {
    return _currentUser != null;
  },
  login: async function(username, password) {
    var result;
    result = (await _socket.emit(LOGIN_EVENT, username, password));
    console.debug('Login success', result);
    _currentUser = result;
    Auth.trigger(LOGIN_EVENT, _currentUser);
    return _currentUser;
  },
  logout: async function() {
    var err;
    try {
      await _socket.emit(LOGOUT_EVENT);
    } catch (error) {
      err = error;
      console.error('Error during logout:', err);
    }
    _currentUser = null;
    return Auth.trigger(LOGOUT_EVENT);
  }
};

_.extend(Auth, Events);

export default Auth;
