var GameSettingsView, SESSION_CHANGE_EVENT, debug,
  boundMethodCheck = function(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new Error('Bound instance method accessed before binding'); } };

import Backbone from 'backbone';

import Marionette from 'backbone.marionette';

import template from 'templates/gamemaster/sessionDetails/gameSettings';

import Toastr from 'toastr';

import SettingsEditorModal from 'views/gamemaster/sessionDetails/settingsEditorModal';

import Socket from 'lib/socket';

import SocketUtils from 'lib/socket/utils';

import Modal from 'lib/modal';

import Debug from 'debug';

debug = Debug('happinov:views:gamemaster:sessiondetails:gamesettings');

SESSION_CHANGE_EVENT = 'session:change';

export default GameSettingsView = (function() {
  class GameSettingsView extends Marionette.View {
    constructor() {
      super(...arguments);
      this.updateSessionDetails = this.updateSessionDetails.bind(this);
    }

    initialize() {
      debug('Initializing GameSettings View:', this.options);
      ({sessionId: this.sessionId} = this.options);
      this.model = new Backbone.Model();
      SocketUtils.registerViewSubscription(this, `${SESSION_CHANGE_EVENT}:${this.sessionId}`, this.updateSessionDetails);
      // @refreshGameZoneNames()
      return this.updateSessionDetails(this.options);
    }

    templateContext() {
      return {
        zone1Id: () => {
          var ref;
          return (ref = this.gameStages) != null ? ref[0].id : void 0;
        },
        zone1Name: () => {
          var ref;
          return (ref = this.gameStages) != null ? ref[0].name : void 0;
        },
        zone2Id: () => {
          var ref;
          return (ref = this.gameStages) != null ? ref[1].id : void 0;
        },
        zone2Name: () => {
          var ref;
          return (ref = this.gameStages) != null ? ref[1].name : void 0;
        }
      };
    }

    async updateSessionDetails(details) {
      var i, len, ref, settings;
      boundMethodCheck(this, GameSettingsView);
      debug('Updating session details');
      if (this.gameZones == null) {
        await this.refreshGameZones();
      }
      ref = details.game_settings;
      for (i = 0, len = ref.length; i < len; i++) {
        settings = ref[i];
        settings.zoneName = this.gameZones[settings.zone].name;
      }
      this.model.set('game_settings', details.game_settings);
      await this.ensureRendered();
      return this.render();
    }

    handleEditSettings() {
      var settings, view;
      settings = this.model.get('game_settings');
      view = new SettingsEditorModal({
        game_settings: settings
      });
      view.on('submit', async(newSettings) => {
        var err;
        if (newSettings !== settings) {
          try {
            return (await Socket.emit('session:update:settings', this.sessionId, newSettings));
          } catch (error) {
            err = error;
            console.error('Error updating session settings:', err);
            return Toastr.error('Error updating session settings.');
          }
        }
      });
      return Modal.show(view);
    }

    async refreshGameZones() {
      var err;
      try {
        this.gameZones = (await Socket.emit('game:zones'));
        return console.debug('Got game zones:', this.gameZones);
      } catch (error) {
        err = error;
        console.error('Error game zones:', err);
        return Toastr.error(`Error game zones: ${err}`);
      }
    }

  };

  GameSettingsView.prototype.template = template;

  GameSettingsView.prototype.className = 'gameSettings';

  GameSettingsView.prototype.ui = {
    editSettings: '#editSettings'
  };

  GameSettingsView.prototype.events = {
    'click @ui.editSettings': 'handleEditSettings'
  };

  return GameSettingsView;

}).call(this);
