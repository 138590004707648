var AVAILABLE_ROOM_COUNT, SessionSettingsEditorView;

import Marionette from 'backbone.marionette';

import template from 'templates/gamemaster/sessionDetails/settingsEditor';

import Backbone from 'backbone';

import Toastr from 'toastr';

import Path from 'path';

import {
  sprintf
} from 'sprintf-js';

import Moment from 'moment';

import Socket from 'lib/socket';

AVAILABLE_ROOM_COUNT = [1, 2, 3, 4];

export default SessionSettingsEditorView = (function() {
  class SessionSettingsEditorView extends Marionette.View {
    initialize() {
      console.debug('Initializing SessionSettingsEditorView:', this.options);
      ({game_settings: this.game_settings} = this.options);
      this.model = new Backbone.Model();
      return this.fetchGameMetadataAndInitializeModel();
    }

    templateContext() {
      return {
        gameZones: () => {
          return this.gameZones;
        },
        availableZoneDurations: () => {
          return this.availableZoneDurations;
        },
        availableroomCount: () => {
          return AVAILABLE_ROOM_COUNT;
        }
      };
    }

    onRender() {
      this.stickit();
      return this.togglePart2Visibility();
    }

    async fetchGameMetadataAndInitializeModel() {
      var err, gameMetadata, id, ref, ref1, ref2, ref3, zone;
      try {
        // Fetch game zones, available zone durations and default settings
        gameMetadata = (await Socket.emit('game:metadata'));
        console.debug('Game metadata fetched', gameMetadata);
        this.gameZones = [];
        ref = gameMetadata.zones;
        for (id in ref) {
          zone = ref[id];
          this.gameZones.push({
            id: id,
            name: zone.name
          });
        }
        this.availableZoneDurations = gameMetadata.availableZoneDurations;
        this.defaultGameSettings = gameMetadata.defaultGameSettings;
        if (!this.game_settings) {
          this.game_settings = this.defaultGameSettings;
        }
        this.model.set('part1_zone', this.game_settings[0].zone);
        this.model.set('part1_duration', this.game_settings[0].duration);
        this.model.set('part1_room_count', this.game_settings[0].roomCount);
        this.model.set('part2_enabled', this.game_settings[1] != null);
        this.model.set('part2_zone', (ref1 = this.game_settings[1]) != null ? ref1.zone : void 0);
        this.model.set('part2_duration', (ref2 = this.game_settings[1]) != null ? ref2.duration : void 0);
        this.model.set('part2_room_count', (ref3 = this.game_settings[1]) != null ? ref3.roomCount : void 0);
        this.model.on('change:part2_enabled', () => {
          return this.togglePart2Visibility();
        });
        this.model.on('change:part1_zone', () => {
          return this.updatePart2Zone();
        });
        this.model.on('change:part2_zone', () => {
          return this.updatePart1Zone();
        });
        await this.ensureRendered();
        return this.render();
      } catch (error) {
        err = error;
        console.error('Error fetching metadata and initializing model:', err);
        return Toastr.error(`Error fetching metadata and initializing model: ${err}`);
      }
    }

    onAttach() {
      return this.ui.sessionNameInput.select();
    }

    updatePart2Zone() {
      if (this.model.get('part1_zone') === 'zone1') {
        return this.model.set('part2_zone', 'zone2');
      } else {
        return this.model.set('part2_zone', 'zone1');
      }
    }

    updatePart1Zone() {
      if (this.model.get('part2_zone') === 'zone1') {
        return this.model.set('part1_zone', 'zone2');
      } else {
        return this.model.set('part1_zone', 'zone1');
      }
    }

    togglePart2Visibility() {
      if (this.model.get('part2_enabled') === true) {
        this.ui.part2Details.show();
        this.updatePart2Zone();
        if (!this.model.get('part2_duration')) {
          this.model.set('part2_duration', this.defaultGameSettings[1].duration);
        }
        if (!this.model.get('part2_room_count')) {
          return this.model.set('part2_room_count', this.defaultGameSettings[1].roomCount);
        }
      } else {
        return this.ui.part2Details.hide();
      }
    }

    getSettings() {
      var game_settings;
      game_settings = [
        {
          zone: this.model.get('part1_zone'),
          duration: this.model.get('part1_duration'),
          roomCount: this.model.get('part1_room_count')
        }
      ];
      if (this.model.get('part2_enabled')) {
        game_settings.push({
          zone: this.model.get('part2_zone'),
          duration: this.model.get('part2_duration'),
          roomCount: this.model.get('part2_room_count')
        });
      }
      return game_settings;
    }

  };

  SessionSettingsEditorView.prototype.template = template;

  SessionSettingsEditorView.prototype.className = 'settingsEditor';

  SessionSettingsEditorView.prototype.ui = {
    sessionNameInput: '#sessionNameInput',
    part2Details: '.part2Details'
  };

  SessionSettingsEditorView.prototype.events = {
    'submit form': 'handleFormSubmit'
  };

  SessionSettingsEditorView.prototype.bindings = {
    '#part1Zone': 'part1_zone',
    '#part1Duration': {
      observe: 'part1_duration',
      onGet: function(val) {
        return `${val}`;
      },
      onSet: function(val) {
        return parseInt(val, 10);
      }
    },
    '#part1roomCount': {
      observe: 'part1_room_count',
      onGet: function(val) {
        return `${val}`;
      },
      onSet: function(val) {
        return parseInt(val, 10);
      }
    },
    '#part2Enabled': 'part2_enabled',
    '#part2Zone': 'part2_zone',
    '#part2Duration': {
      observe: 'part2_duration',
      onGet: function(val) {
        return `${val}`;
      },
      onSet: function(val) {
        return parseInt(val, 10);
      }
    },
    '#part2roomCount': {
      observe: 'part2_room_count',
      onGet: function(val) {
        return `${val}`;
      },
      onSet: function(val) {
        return parseInt(val, 10);
      }
    }
  };

  return SessionSettingsEditorView;

}).call(this);
