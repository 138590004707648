var ROOM_MAPPING_CHANGE_EVENT, ZoneContainerView, debug;

import Marionette from 'backbone.marionette';

import Backbone from 'backbone';

import template from 'templates/gamemaster/monitor/zoneContainer';

import _ from 'lodash';

import ZoneView from 'views/gamemaster/monitor/zone';

import Socket from 'lib/socket';

import SocketUtils from 'lib/socket/utils';

import Debug from 'debug';

debug = Debug('happinov:gamemaster:monitor:zonecontainer');

ROOM_MAPPING_CHANGE_EVENT = 'room:mapping:change';

export default ZoneContainerView = (function() {
  // RESET_ROOM_MAPPING_EVENT = 'reset:room:mapping'
  class ZoneContainerView extends Marionette.CollectionView {
    initialize() {
      debug('Initializing zoneContainer view:', this.options);
      this.collection = new Backbone.Collection();
      SocketUtils.registerViewSubscription(this, ROOM_MAPPING_CHANGE_EVENT, this.updateGameZones);
      return this.fetchGameZones();
    }

    async fetchGameZones() {
      var err, zones;
      try {
        zones = (await Socket.emit('get:game:zones'));
        console.debug('Game zones fetched', zones);
        return this.updateGameZones(zones);
      } catch (error) {
        err = error;
        return debug('Error fetching game zones', err);
      }
    }

    updateGameZones(zones) {
      return this.collection.reset(_.map(zones, function(zone, id) {
        return {
          id: id,
          name: zone.name,
          rooms: zone.rooms
        };
      }));
    }

  };

  ZoneContainerView.prototype.template = template;

  ZoneContainerView.prototype.className = 'zoneContainer';

  ZoneContainerView.prototype.childView = ZoneView;

  ZoneContainerView.prototype.childViewContainer = '.zones';

  return ZoneContainerView;

}).call(this);
