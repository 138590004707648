import Marionette from 'backbone.marionette'
import template from 'templates/gamemaster/monitor'

import ClockView from 'views/common/clock'
import ZoneContainerView from 'views/gamemaster/monitor/zoneContainer'

import Socket from 'lib/socket'
import Swal from 'lib/swal'
import Toastr from 'toastr'

import Debug from 'debug'
const debug = Debug('happinov:gamemaster:monitor')

const RESET_ROOM_MAPPING_EVENT = 'reset:room:mapping'

const MonitorView = Marionette.View.extend({
	template: template,
	className: 'monitor container',

	regions: {
		zonesRegion: '#zonesRegion',
		clockRegion: '#clockRegion',
	},

	ui: {
		resetRoomMappingButton: '#resetRoomMappingButton',
	},

	events: {
		'click this.ui.resetRoomMappingButton': 'handleResetRoomMapping',
	},

	initialize() {
		debug('Initializing monitor view:', this.options)
	},

	onRender() {
		this.showChildView('clockRegion', new ClockView())
		this.showChildView('zonesRegion', new ZoneContainerView())
	},

	handleResetRoomMapping() {
		Swal.confirm('Réinitialiser le mapping des salles?', async ()=> {
			try {
				await Socket.emit(RESET_ROOM_MAPPING_EVENT)
				Toastr.success('Room mapping reset')
			} catch(err) {
				console.error('Error resetting room mapping:',err)
				Toastr.error('Error resetting room mapping.')
			}
		})
	},
})

export default MonitorView
