var ensureRendered;

import 'bootstrap';

import Metadata from 'metadata';

import DocumentUtils from 'lib/document';

DocumentUtils.setBaseTitle(Metadata.name);

import Toastr from 'toastr';

Toastr.options.positionClass = 'toast-bottom-left';

Toastr.options.newestOnTop = false;

import Marionette from 'backbone.marionette';

import 'backbone.stickit';

ensureRendered = function() {
  return new Promise((resolve) => {
    if (this.isRendered()) {
      return resolve();
    } else {
      return this.once('render', resolve);
    }
  });
};

Marionette.View.prototype.ensureRendered = ensureRendered;

Marionette.CollectionView.prototype.ensureRendered = ensureRendered;

import $ from 'jquery';

$.fn.replaceClass = function(removed, added) {
  var filterArgument;
  filterArgument = function(value) {
    if (!Array.isArray(value)) {
      if (value != null) {
        value = [value];
      } else {
        value = [];
      }
    }
    return value;
  };
  removed = filterArgument(removed);
  added = filterArgument(added);
  return this.each(function() {
    this.classList.remove(...removed);
    return this.classList.add(...added);
  });
};

import JQueryUtils from 'lib/jquery';

JQueryUtils.makeLongpressAvailable();
