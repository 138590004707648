var GameLanguages, KnownLanguages, Lang, LanguageMap;

import _ from 'lodash';

import Config from 'etc/config';

GameLanguages = Config.languages.available;

LanguageMap = {};

GameLanguages.forEach(function(lang) {
  return LanguageMap[lang.id] = lang;
});

KnownLanguages = _.map(GameLanguages, 'id');

export default Lang = {
  getFlag: function(languageCode) {
    var ref, ref1;
    return (ref = (ref1 = LanguageMap[languageCode]) != null ? ref1.flag : void 0) != null ? ref : `[${languageCode}]`;
  },
  list: function(langList) {
    var result, unknownLangs;
    if (langList != null) {
      result = _.filter(GameLanguages, function(lang) {
        return _.includes(langList, lang.id);
      });
      unknownLangs = _.reject(langList, function(lang) {
        return _.includes(KnownLanguages, lang);
      });
      unknownLangs.forEach(function(lang) {
        return result.push({
          id: lang,
          name: lang
        });
      });
      return result;
    } else {
      return GameLanguages;
    }
  },
  getDefault: function() {
    return Config.languages.default;
  }
};
