var DRAGENTER_EVENT, DRAGLEAVE_EVENT, GENERIC_UPLOAD_ERROR, MIME_TYPE_ZIP, MIME_TYPE_ZIP_ALT, ManageGameView, SUPPORTED_MIME_TYPES, VISIBLE_CLASS, debug,
  boundMethodCheck = function(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new Error('Bound instance method accessed before binding'); } };

import Marionette from 'backbone.marionette';

import template from 'templates/gamemaster/manageGame';

import _ from 'lodash';

import Toastr from 'toastr';

import Path from 'path';

import Moment from 'moment';

import Time from 'lib/time';

import FileUtils from 'lib/file';

import Socket from 'lib/socket';

GENERIC_UPLOAD_ERROR = 'UPLOAD_ERROR';

DRAGENTER_EVENT = 'dragenter';

DRAGLEAVE_EVENT = 'dragleave';

VISIBLE_CLASS = 'visible';

MIME_TYPE_ZIP = 'application/zip';

MIME_TYPE_ZIP_ALT = 'application/x-zip-compressed';

SUPPORTED_MIME_TYPES = [MIME_TYPE_ZIP, MIME_TYPE_ZIP_ALT];

import Debug from 'debug';

debug = Debug('happinov:gamemaster:manage:game');

export default ManageGameView = (function() {
  class ManageGameView extends Marionette.View {
    constructor() {
      super(...arguments);
      this.handleDragEnter = this.handleDragEnter.bind(this);
    }

    initialize() {
      console.debug('Initializing ManageGameView:', this.options);
      this.updateGameDefinition();
      // must defer to avoid non bound methods to be used in event listener.
      return Time.setTimeout(0, this, () => {
        return document.body.addEventListener(DRAGENTER_EVENT, this.handleDragEnter);
      });
    }

    templateContext() {
      var ref, ref1;
      return {
        gamedataDownloadUrl: "services/gamedata",
        gameId: (ref = this.gameMetadata) != null ? ref.id : void 0,
        gameVersion: (ref1 = this.gameMetadata) != null ? ref1.version : void 0
      };
    }

    async updateGameDefinition() {
      this.gameMetadata = (await Socket.emit('game:metadata'));
      debug('Game definition loaded:', this.gameMetadata);
      this.ensureRendered();
      return this.render();
    }

    async handleGameDataFileInput() {
      var err, file;
      try {
        file = (await FileUtils.selectFile('.zip'));
        return this.uploadFile(file);
      } catch (error) {
        err = error;
        console.error(err);
        return Toastr.error(err);
      }
    }

    stopEventProcessing(event) {
      event.stopPropagation();
      return event.preventDefault();
    }

    hasFiles(dragEvent) {
      var types;
      if (dragEvent.originalEvent) {
        dragEvent = dragEvent.originalEvent;
      }
      types = dragEvent.dataTransfer.types;
      return (!_.isEmpty(types)) && types[0] === 'Files';
    }

    getEventFiles(dragEvent) {
      var ref;
      if (dragEvent.originalEvent) {
        dragEvent = dragEvent.originalEvent;
      }
      return (ref = dragEvent.dataTransfer) != null ? ref.files : void 0;
    }

    handleDragEnter(event) {
      boundMethodCheck(this, ManageGameView);
      console.debug("drag enter");
      if (this.hasFiles(event)) {
        this.stopEventProcessing(event);
        return this.ui.dragOverlay.addClass(VISIBLE_CLASS);
      }
    }

    handleDragLeave(event) {
      console.debug("drag leave");
      this.stopEventProcessing(event);
      return this.ui.dragOverlay.removeClass(VISIBLE_CLASS);
    }

    handleDrop(event) {
      var droppedFiles, file;
      this.handleDragLeave(event);
      debug('Content dropped:', event, event.originalEvent.dataTransfer.files);
      if (this.hasFiles(event)) {
        droppedFiles = this.getEventFiles(event);
        debug('Received dropped files:', droppedFiles);
        if (droppedFiles.length === 1) {
          file = droppedFiles[0];
          return this.uploadFile(file);
        } else {
          console.error("Multiple files upload not supported.");
          return Toastr.error("Multiple files upload not supported.");
        }
      } else {
        return debug('Ignoring drop without file');
      }
    }

    async uploadFile(file) {
      var err;
      if (SUPPORTED_MIME_TYPES.includes(file.type)) {
        try {
          await FileUtils.upload('services/upload/gamedata', {
            file: file
          });
          debug('File upload success');
          Toastr.success(`File uploaded successfully: ${file.name}`);
          return this.updateGameDefinition();
        } catch (error) {
          err = error;
          if (!err) {
            err = GENERIC_UPLOAD_ERROR;
          }
          return Toastr.error(`Error while uploading ${file.name}: ${err}`);
        }
      } else {
        console.error("File to upload isn't supported:", file.type);
        return Toastr.error("File to upload isn't supported");
      }
    }

  };

  ManageGameView.prototype.template = template;

  ManageGameView.prototype.className = 'manageGame container';

  ManageGameView.prototype.ui = {
    dragOverlay: '#dragOverlay',
    uploadGameDataButton: '#uploadGameDataButton'
  };

  ManageGameView.prototype.events = {
    'click @ui.uploadGameDataButton': 'handleGameDataFileInput',
    'dragleave @ui.dragOverlay': 'handleDragLeave',
    'dragover @ui.dragOverlay': 'stopEventProcessing', // Required to be able to receive drop event.
    'drop @ui.dragOverlay': 'handleDrop'
  };

  return ManageGameView;

}).call(this);
