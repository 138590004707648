var Modal, createRegion, currentView, hideModal, region, showModal, stopListening;

import Marionette from 'backbone.marionette';

import Focus from 'lib/focus';

import 'bootstrap';

import $ from 'jquery';

createRegion = function() {
  var $el;
  // $el = $('<div class="modal fade" role="dialog">') # Removed 'fade' class that prevents 'shown' event from firing.
  $el = $('<div class="modal" role="dialog" data-backdrop="static" tabindex="-1">'); // tabindex is required to allow closing modal with 'escape' key.
  $el.appendTo(document.body);
  return new Marionette.Region({
    el: $el
  });
};

region = createRegion();

currentView = false;

region.$el.on('hide.bs.modal', function() {
  var ref;
  console.debug('Modal hide.');
  return (ref = region.currentView) != null ? ref.triggerMethod('modal:hide') : void 0;
});

region.$el.on('hidden.bs.modal', function() {
  var ref;
  console.debug('Modal is hidden.');
  if ((ref = region.currentView) != null) {
    ref.triggerMethod('modal:hidden');
  }
  return region.empty(); // remove dom structure on close.
});

region.$el.on('show.bs.modal', function() {
  var ref;
  console.debug('Modal show.');
  return (ref = region.currentView) != null ? ref.triggerMethod('modal:show') : void 0;
});

region.$el.on('shown.bs.modal', function() {
  var ref;
  console.debug('Modal is shown.');
  return (ref = region.currentView) != null ? ref.triggerMethod('modal:shown') : void 0;
});

showModal = function() {
  return region.$el.modal();
};

hideModal = function() {
  return region.$el.modal('hide');
};

stopListening = function() {
  if (currentView) {
    currentView.off('attach');
    return currentView.off('modal:close');
  }
};

export default Modal = {
  show: function(view) {
    Focus.clear();
    stopListening();
    view.on('attach', function() {
      return showModal();
    });
    view.on('modal:close', function() {
      hideModal();
      stopListening();
      return currentView = false;
    });
    region.show(view);
    return currentView = view;
  },
  hide: function() {
    hideModal();
    stopListening();
    return currentView = false;
  }
};
