import * as Handlebars from 'handlebars/runtime';
import deviceTypeIcon from 'templates/partials/deviceTypeIcon';
import navbar from 'templates/partials/navbar';
Handlebars.registerHelper({});
Handlebars.registerPartial({deviceTypeIcon,navbar});
export default Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "			<option value=\""
    + alias2(alias1(depth0, depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "'</option>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "			<option value=\""
    + alias2(alias1(depth0, depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"form-group row\">\n	<label class=\"col-sm-3 col-form-label text-right\">Part 1:</label>\n	<div class=\"col-sm-2\">\n		<select id=\"part1Zone\" class=\"form-control\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"gameZones") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":3},"end":{"line":7,"column":12}}})) != null ? stack1 : "")
    + "		</select>\n	</div>\n	<label class=\"col-form-label text-right col-sm-1\">Duration:</label>\n	<div class=\"col-sm-2\">\n		<select id=\"part1Duration\" class=\"form-control\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"availableZoneDurations") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":3},"end":{"line":15,"column":12}}})) != null ? stack1 : "")
    + "		</select>\n	</div>\n	<label class=\"col-form-label text-right col-sm-1\">Rooms:</label>\n	<div class=\"col-sm-2\">\n		<select id=\"part1roomCount\" class=\"form-control\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"availableroomCount") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":3},"end":{"line":23,"column":12}}})) != null ? stack1 : "")
    + "		</select>\n	</div>\n</div>\n<div class=\"form-group row\">\n	<label for=\"part2Enabled\" class=\"col-sm-3 col-form-label text-right\">\n		<input type=\"checkbox\" id=\"part2Enabled\">\n		Part 2:\n	</label>\n	<div class=\"col-sm-2 part2Details\">\n		<select id=\"part2Zone\" class=\"form-control\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"gameZones") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":3},"end":{"line":36,"column":12}}})) != null ? stack1 : "")
    + "		</select>\n	</div>\n	<label class=\"col-form-label text-right col-sm-1 part2Details\">Duration:</label>\n	<div class=\"col-sm-2 part2Details\">\n		<select id=\"part2Duration\" class=\"form-control\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"availableZoneDurations") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":3},"end":{"line":44,"column":12}}})) != null ? stack1 : "")
    + "		</select>\n	</div>\n	<label class=\"col-form-label text-right col-sm-1 part2Details\">Rooms:</label>\n	<div class=\"col-sm-2 part2Details\">\n		<select id=\"part2roomCount\" class=\"form-control\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"availableroomCount") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":3},"end":{"line":52,"column":12}}})) != null ? stack1 : "")
    + "		</select>\n	</div>\n</div>\n";
},"useData":true});