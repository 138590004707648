// coffeelint: disable=missing_fat_arrows
export default function(testedValue, referenceValue, options) {
  if (testedValue === referenceValue) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
};

// coffeelint: enable=missing_fat_arrows
