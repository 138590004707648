import * as Handlebars from 'handlebars/runtime';
import deviceTypeIcon from 'templates/partials/deviceTypeIcon';
import navbar from 'templates/partials/navbar';
Handlebars.registerHelper({});
Handlebars.registerPartial({deviceTypeIcon,navbar});
export default Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<tr class=\"part-details\">\n			<td class=\"zone\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"zoneName") : depth0), depth0))
    + "</td>\n			<td class=\"duration\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"duration") : depth0), depth0))
    + "'</td>\n			<td class=\"roomCount\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"roomCount") : depth0), depth0))
    + "</td>\n		</tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h3 class=\"card-header\">\n	<span id=\"gameSettingsTitle\">Game Settings</span>\n\n	<div class=\"flexSpacer\"></div>\n\n	<button class=\"btn btn-light\" type=\"button\" id=\"editSettings\"><i class=\"far fa-pencil icon\"></i></button>\n</h3>\n<table class=\"table table-striped mb-0\">\n	<thead>\n		<tr>\n			<th><i class=\"far fa-map-marker icon\"></i> Zone</th>\n			<th><i class=\"far fa-hourglass-half icon\"></i> Duration</th>\n			<th><i class=\"far fa-door-open icon\"></i> Room count</th>\n		</tr>\n	</thead>\n	<tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"game_settings") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":2},"end":{"line":23,"column":11}}})) != null ? stack1 : "")
    + "	</tbody>\n</table>\n";
},"useData":true});