var INBOUND_CLASS, MESSAGE_CLASS, MessagingConsoleView, OUTBOUND_CLASS, createMessageEntry, getCurrentTime;

import Marionette from 'backbone.marionette';

import template from 'templates/common/messagingConsole';

import messageTemplate from 'templates/common/messagingConsole/message';

import Moment from 'moment';

import _ from 'lodash';

MESSAGE_CLASS = 'message';

INBOUND_CLASS = 'inbound';

OUTBOUND_CLASS = 'outbound';

getCurrentTime = function() {
  return Moment().format('HH:mm:ss');
};

createMessageEntry = function(msg, originalMessage, isInbound) {
  return messageTemplate({
    message: msg,
    originalMessage: originalMessage,
    isInbound: isInbound,
    time: new Date()
  });
};

export default MessagingConsoleView = (function() {
  class MessagingConsoleView extends Marionette.View {
    initialize() {
      return console.debug('Initializing MessagingConsoleView:', this.options);
    }

    onAttach() {
      return this.grabFocus();
    }

    grabFocus() {
      if (!this.options.noFocus) {
        return this.ui.messageInput.focus();
      }
    }

    scrollLogsToBottom() {
      return this.ui.log.scrollTop(this.ui.log.prop('scrollHeight'));
    }

    handleFormSubmit(event) {
      var messageString;
      event.preventDefault();
      event.stopPropagation();
      messageString = this.ui.messageInput.val().trim();
      if (_.isEmpty(messageString)) {
        return console.debug('Ignoring empty message.');
      } else {
        this.ui.messageInput.val(null);
        return this.sendMessage(messageString);
      }
    }

    sendMessage(msg) {
      return this.trigger('submit', msg);
    }

    async _addMessage(isInbound, message, originalMessage) {
      console.debug('Adding message:', (isInbound ? 'in' : 'out'), message);
      await this.ensureRendered();
      this.ui.log.append(createMessageEntry(message, originalMessage, isInbound));
      return this.scrollLogsToBottom();
    }

    addInboundMessage(message) {
      return this._addMessage(true, message);
    }

    addOutboundMessage(message, original) {
      return this._addMessage(false, message, original);
    }

    handleResend(event) {
      return this.sendMessage(event.currentTarget.dataset.originalMessage);
    }

    handleCopy(event) {
      return this.ui.messageInput.val(event.currentTarget.dataset.originalMessage);
    }

  };

  MessagingConsoleView.prototype.template = template;

  MessagingConsoleView.prototype.className = 'messagingConsole';

  MessagingConsoleView.prototype.ui = {
    messageInput: '.messageInput',
    log: '.log'
  };

  MessagingConsoleView.prototype.events = {
    click: 'grabFocus',
    'submit form': 'handleFormSubmit',
    'click .resendButton': 'handleResend',
    'click .copyButton': 'handleCopy'
  };

  return MessagingConsoleView;

}).call(this);
