var CONNECTION_TIMEOUT, DEFAULT_OPTIONS, Socket, _socket, debug;

import io from 'socket.io-client';

import _ from 'lodash';

import Time from 'lib/time';

CONNECTION_TIMEOUT = 1000;

DEFAULT_OPTIONS = {
  reconnection: true,
  reconnectionAttempts: 2e308,
  timeout: 1000
};

_socket = null;

import Debug from 'debug';

debug = Debug('happinov:lib:socket');

export default Socket = {
  connect: function(channel = '/') {
    debug('Socket connecting to:', channel);
    return _socket = io(channel, DEFAULT_OPTIONS);
  },
  getSocket: function() {
    return _socket;
  },
  emit: async function(...args) {
    var callback;
    debug('Emitting:', ...args);
    if (_.isFunction(_.last(args))) {
      debug('Emit has a callback:', ...args);
      callback = _.pop();
    }
    return (await new Promise(function(resolve, reject) {
      return _socket.emit(...args, function(err, result) {
        if (callback != null) {
          callback(err, result);
        }
        if (err) {
          return reject(err);
        } else {
          return resolve(result);
        }
      });
    }));
  },
  on: function(...args) {
    return _socket.on(...args);
  },
  off: function(...args) {
    return _socket.off(...args);
  },
  once: function(...args) {
    return _socket.once(...args);
  },
  hasListeners: function(...args) {
    return _socket.hasListeners(...args);
  },
  isConnected: function() {
    var ref;
    return (ref = _socket != null ? _socket.connected : void 0) != null ? ref : false;
  },
  isDisconnected: function() {
    var ref;
    return (ref = _socket != null ? _socket.disconnected : void 0) != null ? ref : true;
  },
  close: function() {
    if (_socket != null) {
      _socket.close();
    }
    return _socket = null;
  }
};
