var BannerView, debug;

import Marionette from 'backbone.marionette';

import Backbone from 'backbone';

import template from 'templates/common/banner';

import Debug from 'debug';

debug = Debug('happinov:common:banner');

export default BannerView = (function() {
  class BannerView extends Marionette.View {
    initialize() {
      debug('Initializing banner view:', this.options);
      return this.model = new Backbone.Model(this.options);
    }

  };

  BannerView.prototype.template = template;

  BannerView.prototype.className = 'banner';

  return BannerView;

}).call(this);
