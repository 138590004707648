var Time, bindCallback;

import _ from 'lodash';

bindCallback = function(obj, callback) {
  if (_.isFunction(obj)) {
    // we don't have an object to bind.
    callback = obj;
  } else {
    callback = callback.bind(obj);
  }
  return callback;
};

export default Time = {
  setTimeout: function(duration, obj, callback) {
    return setTimeout(bindCallback(obj, callback), duration);
  },
  setInterval: function(duration, obj, callback) {
    return setInterval(bindCallback(obj, callback), duration);
  },
  getDuration: function(recordedDate) {
    return Date.now() - recordedDate;
  },
  wait: function(millis) {
    return new Promise(function(resolve) {
      return setTimeout(resolve, millis);
    });
  },
  yield: async function() {
    return (await Time.wait(0));
  }
};
