var Config;

export default Config = {
  languages: {
    default: 'fr',
    available: [
      {
        id: 'fr',
        name: 'French',
        flag: '🇫🇷'
      },
      {
        id: 'en',
        name: 'English',
        flag: '🇬🇧'
      }
    ]
  },
  defaultSessionName: 'Session de %s'
};
