
/*
 * NOTE: server side subscription doent track how many times subscriptions are registered so we need to do it on this side.
 * Multiple similar subscriptions can happen when a view is created and the current one hasn't been destroyed yet.
 */
var DEVICES_LIST_SUBSCRIPTION, DEVICES_STATE_SUBSCRIPTION, SESSION_DEVICE_LIST_EVENT, SocketUtils, debug, decrementSubCount, hasPositiveSubCount, incrementSubCount, subscriptionCounts;

import Debug from 'debug';

import Socket from 'lib/socket';

DEVICES_LIST_SUBSCRIPTION = 'device:list';

DEVICES_STATE_SUBSCRIPTION = 'device:state';

SESSION_DEVICE_LIST_EVENT = 'session:device:list';

subscriptionCounts = {};

debug = Debug('happinov:lib:socket:utils');

incrementSubCount = function(name) {
  if (subscriptionCounts[name] != null) {
    return subscriptionCounts[name]++;
  } else {
    return subscriptionCounts[name] = 1;
  }
};

decrementSubCount = function(name) {
  if (hasPositiveSubCount(name)) {
    return subscriptionCounts[name]--;
  } else {
    throw new Error(`Negative subscription count reached for: ${name}`);
  }
};

hasPositiveSubCount = function(name) {
  return (subscriptionCounts[name] != null) && subscriptionCounts[name] > 0;
};

export default SocketUtils = {
  subscribe: async function(subscriptionType, callback) {
    var err;
    try {
      if (!hasPositiveSubCount(subscriptionType)) { // then we're already subscribed.
        debug('Subscribing:', subscriptionType);
        await Socket.emit('subscribe', subscriptionType);
      }
      incrementSubCount(subscriptionType);
      Socket.on(subscriptionType, callback);
      return console.debug('Subscribed to:', subscriptionType);
    } catch (error) {
      err = error;
      return console.error('Error subscribing:', subscriptionType, err);
    }
  },
  unsubscribe: async function(subscriptionType, callback) {
    var err;
    try {
      Socket.off(subscriptionType, callback);
      decrementSubCount(subscriptionType);
      if (!hasPositiveSubCount(subscriptionType)) { // then we still have running subscriptions
        debug('Unsubscribing:', subscriptionType);
        return (await Socket.emit('unsubscribe', subscriptionType));
      }
    } catch (error) {
      err = error;
      return console.error('Error unsubscribing:', subscriptionType, err);
    }
  },
  registerViewSubscription: function(view, eventName, callback, ...args) {
    callback = callback.bind(view); // make sure callback context is maintained.
    SocketUtils.subscribe(eventName, callback, ...args);
    return view.on('destroy', function() {
      return SocketUtils.unsubscribe(eventName, callback);
    });
  },
  registerDeviceSubscriptions: async function(view, listHandler, stateHandler, errorHandler) {
    var err;
    listHandler = listHandler.bind(view);
    stateHandler = stateHandler.bind(view);
    errorHandler = errorHandler.bind(view);
    SocketUtils.registerViewSubscription(view, DEVICES_STATE_SUBSCRIPTION, stateHandler);
    SocketUtils.registerViewSubscription(view, DEVICES_LIST_SUBSCRIPTION, async function(list) {
      var err;
      listHandler(list);
      try {
        // must refresh states after getting new device list.
        return stateHandler((await Socket.emit(DEVICES_STATE_SUBSCRIPTION)));
      } catch (error) {
        err = error;
        return errorHandler(err);
      }
    });
    try {
      listHandler((await Socket.emit(DEVICES_LIST_SUBSCRIPTION)));
      return stateHandler((await Socket.emit(DEVICES_STATE_SUBSCRIPTION)));
    } catch (error) {
      err = error;
      return errorHandler(err);
    }
  },
  registerSessionDeviceSubscriptions: async function(view, sessionId, listHandler, stateHandler, errorHandler) {
    var err, sessionDeviceListEvent;
    listHandler = listHandler.bind(view);
    stateHandler = stateHandler.bind(view);
    errorHandler = errorHandler.bind(view);
    sessionDeviceListEvent = `${SESSION_DEVICE_LIST_EVENT}:${sessionId}`;
    SocketUtils.registerViewSubscription(view, DEVICES_STATE_SUBSCRIPTION, stateHandler);
    SocketUtils.registerViewSubscription(view, sessionDeviceListEvent, (async function(list) {
      var err;
      listHandler(list);
      try {
        // must refresh states after getting new device list.
        return stateHandler((await Socket.emit(DEVICES_STATE_SUBSCRIPTION)));
      } catch (error) {
        err = error;
        return errorHandler(err);
      }
    }), sessionId);
    try {
      listHandler((await Socket.emit(SESSION_DEVICE_LIST_EVENT, sessionId)));
      return stateHandler((await Socket.emit(DEVICES_STATE_SUBSCRIPTION)));
    } catch (error) {
      err = error;
      return errorHandler(err);
    }
  }
};
