import * as Handlebars from 'handlebars/runtime';
import deviceTypeIcon from 'templates/partials/deviceTypeIcon';
import navbar from 'templates/partials/navbar';
Handlebars.registerHelper({});
Handlebars.registerPartial({deviceTypeIcon,navbar});
export default Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"card-header room-header\">\n	<div class=\"roomName\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</div>\n	<div class=\"dropdown\">\n		<button class=\"btn btn-outline-secondary btn-sm\" type=\"button\" id=\"roomMenuButton\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\">\n			<i class=\"fas fa-cog\"></i>\n		</button>\n		<div class=\"dropdown-menu\" aria-labelledby=\"roomMenuButton\">\n			<button class=\"dropdown-item resetStepButton\"><i class=\"fas fa-step-backward\"></i> Reset Step</button>\n			<button class=\"dropdown-item restartGameButton\"><i class=\"fas fa-redo\"></i> Restart Game</button>\n			<div class=\"dropdown-divider\"></div>\n			<button class=\"dropdown-item replaceButton\"><i class=\"fas fa-exchange\"></i> Replace step</button>\n		</div>\n	</div>\n</div>\n</div>\n<div class=\"card-body\">\n	<div class=\"teams\"></div>\n</div>\n";
},"useData":true});