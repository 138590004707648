var DEFAULT_TITLE, DocumentUtils, baseTitle, debug, setTitle;

import Debug from 'debug';

debug = Debug('happinov:lib:document');

DEFAULT_TITLE = '🤓';

baseTitle = DEFAULT_TITLE;

setTitle = function(title) {
  return document.title = title;
};

setTitle(baseTitle);

export default DocumentUtils = {
  setBaseTitle: function(title) {
    baseTitle = title;
    return DocumentUtils.resetTitle();
  },
  setTitle: function(title) {
    return setTitle(`${title} - ${baseTitle}`);
  },
  resetTitle: function() {
    return setTitle(baseTitle);
  }
};
